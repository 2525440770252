import React, { useEffect, useState } from "react";
import { Col, Row, Spinner } from "react-bootstrap";
import { FaCircleXmark } from "react-icons/fa6";
import { Button, Input } from "../CustomComponents";
import ModalContainer from "../ModalContainer";
import cssStyles from "./styles/editModal.module.scss";
import _ from "lodash";
import Toast, { SuccessToast } from "../../Hooks/useToast";
import {
  useAddBusinessTypeMutation,
  useUpdateBusinessTypeMutation,
} from "../../Data/services/businessTypeApi";

const EditBusinessTypeModal = ({ handleEditModalClose, editData, refetch }) => {
  const [rowId, setRowId] = useState("");
  const [businessTitle, setBusinessTitle] = useState("");
  const [sending, setSending] = useState(false);

  const [mounted, setMounted] = React.useState(false);
  useEffect(() => {
    setMounted(true);
  }, []);

  useEffect(() => {
    // console.log(editData.business_title);
    // console.log(editData.slug);
    if (editData) {
      setRowId(editData.slug);
      setBusinessTitle(editData.business_title);
    }
  }, [editData, mounted]);

  const handleBusinessTitle = (e) => {
    // console.log(e.target.value);
    setBusinessTitle(e.target.value);
  };

  const [addBusinessType, { isLoading: adding }] = useAddBusinessTypeMutation();
  const [updateBusinessType, { isLoading: updating }] =
    useUpdateBusinessTypeMutation();

  const handleSubmit = async (e) => {
    // console.log(rowId);
    // console.log(businessTitle);

    if (businessTitle.length <= 2 || _.isEmpty(businessTitle)) {
      Toast("Minimum 3 characters required.", "error");
      return;
    }

    const data = {
      business_title: businessTitle,
    };

    setSending(true);
    try {
      let response;
      if (editData) {
        response = await updateBusinessType({ data, slug: rowId });
      } else {
        response = await addBusinessType(data);
      }

      if (response.data.code === 200) {
        refetch();
        setSending(false);
        SuccessToast(response?.data.message);
        handleEditModalClose();
      }
    } catch (error) {
      if (error?.response && error?.response?.data?.message) {
        Toast(error?.response?.data?.message, "error");
      } else {
        Toast("An error occurred during login", "error");
      }
      handleEditModalClose();
      setSending(false);
    }
  };

  if (!mounted) {
    return;
  }

  return (
    <div>
      <ModalContainer handleClose={handleEditModalClose}>
        <div className={cssStyles.modalContainer}>
          <div className="d-flex align-items-center w-100 justify-content-between mb-4">
            <p className="fs-4 fw-medium m-0 text-black">
              {editData ? "Edit Business Type" : "Add Business Type"}
            </p>
            <span className="bg-dark bg-opacity-75 d-flex p-3 rounded-5">
              <FaCircleXmark
                role="button"
                onClick={handleEditModalClose}
                style={{ cursor: "pointer" }}
              />
            </span>
          </div>
          <div className="w-100">
            {
              <Row>
                <Col md={12}>
                  <Input
                    classes="mt-2 mb-3"
                    type="text"
                    placeholder="Enter business type"
                    value={businessTitle}
                    onChange={handleBusinessTitle}
                  />
                </Col>
              </Row>
            }

            <Row>
              <Col md={12}>
                <Button
                  classes={`${cssStyles.cardBtn} mt-4 `}
                  text={
                    sending ? (
                      <Spinner
                        animation="grow"
                        size="sm"
                        className="mx-3 my-1"
                      />
                    ) : editData ? (
                      "Update"
                    ) : (
                      "Save"
                    )
                  }
                  onClick={handleSubmit}
                ></Button>
              </Col>
            </Row>
          </div>
        </div>
      </ModalContainer>
    </div>
  );
};

export default EditBusinessTypeModal;
