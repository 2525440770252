import axios from "axios";
import React, { lazy, Suspense, useCallback, useEffect, useState } from "react";
import "react-bootstrap-table-next/dist/react-bootstrap-table2.min.css";
import "react-bootstrap-table2-toolkit/dist/react-bootstrap-table2-toolkit.min.css";
import { Button, SubTitle } from "../../Components/CustomComponents/index.js";
import Table from "../../Components/Table/Table.js";
import Toast, { SuccessToast } from "../../Hooks/useToast.js";
import styles from "./styles/user_management.module.scss";
// import { ToastContainer, toast } from "react-toastify";
import { FaUserEdit } from "react-icons/fa";
//
import { useDispatch } from "react-redux"; // for getting and setting data into local redux
// import CustomerBlockModal from "../../Components/Modals/CustomerBlockModal";
import { debounce } from "lodash";
import { Col, Row, Spinner } from "react-bootstrap";
import { MdDeleteForever } from "react-icons/md";
import DeleteModal from "../../Components/Modals/DeleteModal.js";
import EditBusinessTypeModal from "../../Components/Modals/EditBusinessTypeModal.js";
import { useDeleteBusinessTypeMutation, useGetAllBusinessTypeMutation, useGetAllBusinessTypeQuery } from "../../Data/services/businessTypeApi.js";
import CustomLoader from "../../Components/CustomComponents/CustomLoader.js";
const CustomerBlockModal = lazy(() =>
  import("../../Components/Modals/CustomerBlockModal.js")
);

const BusinessTypeManagementScreen = ({ setPage }) => {
  const dispatch = useDispatch();

  // const [data, setData] = useState([]);
  const [indicationMessage, setIndicationMessage] = useState("loading...");
  const [rowData, setRowData] = useState([]);
  const [editedData, setEditedData] = useState("");

  const [loading, setLoading] = useState(false);
  const [editModal, setEditModal] = useState(false);
  const [deleteModal, setDeleteModal] = useState(false);

  const [currentPage, setCurrentPage] = useState(1); // todo
      const [pageSize, setPageSize] = useState(10); // todo
      const [totalRecords, setTotalRecords] = useState(0); // todo
      const [totalPages, setTotalPages] = useState(0); // todo
      const [dataFetched, setDataFetched] = useState(false); // todo

  const [searchQuery, setSearchQuery] = useState("");

  const { data, isSuccess, isLoading, isFetching, refetch } =
  useGetAllBusinessTypeQuery(
         {
           search: searchQuery.length >= 3 ? searchQuery : "",
           limit: pageSize,
           page: currentPage,
         },
         { refetchOnMountOrArgChange: true }
       );

       useEffect(() => {
             if (data) {
               // console.log(data);
               setPageSize(data.links.per_page);
               setCurrentPage(data.links.current);
               setTotalRecords(data.links.total_records);
               setTotalPages(data.links.total);
               setRowData(data.data);
               setDataFetched(true);
             }
           }, [data]);

  // const debouncedSearch = useCallback(
  //   debounce((query) => {
  //     console.log("Search query:", query);
  //     // local search logic
  //     const filtered = dummy_data.filter((item) =>
  //       [item.firstname, item.lastname, item.username, item.email]
  //         .filter(Boolean) // Remove null/undefined fields
  //         .some((field) => field.toLowerCase().includes(query.toLowerCase()))
  //     );

  //     setRowData(filtered);

  //     // Trigger your search logic or API here
  //   }, 500), // Adjust debounce delay as needed
  //   []
  // );

  const handleSearchChange = (event) => {
    const query = event.target.value;
    // console.log("Search query:", query); // You can use this to trigger a search function
    setSearchQuery(query);

    // debouncedSearch(query);
  };


  const handleDeleteModalOpen = () => {
    setDeleteModal(true);
  };

  const handleDeleteModalClose = () => {
    setDeleteModal(false);
    setEditedData("");
  };

  const handleEditModalOpen = () => {
    setEditModal(true);
  };

  const handleEditModalClose = () => {
    setEditModal(false);
    setEditedData("");
  };

  const handleAddUser = () => {
    setEditModal(true);
  };

  const[deleteBusinessType, {isLoading: isDeleting}] = useDeleteBusinessTypeMutation();

  const deleteData = async (slug) => {


    try {
      const { data } = await deleteBusinessType(slug);

      // console.log(data);

      if (data.code === 200) {
        await refetch();
        handleDeleteModalClose();
        SuccessToast(data.message);
      }
    } catch (error) {
      // console.log(error);
      Toast(error.message, "error");
    }
    
  };


  function indication() {
    // return something here
    // return 'Table is empty';
    return indicationMessage;
  }

  const columns = [
    {
      dataField: "business_title",
      text: "Business Type",
      sort: true,
      headerStyle: {
        width: "200px",
      },
    },
    {
      dataField: "actions",
      text: "Actions",
      headerAlign: "center",
      align: "center",
      headerStyle: {
        width: "100px",
      },
      formatter: (cellContent, row) => {
        // console.log(row)
        if (row.slug) {
          return (
            <div className="d-flex justify-content-center align-items-center ">
              <div
                className={`${styles.btnBg} d-flex justify-content-center align-items-center rounded-3 mx-2 p-2 text-white`}
                role="button"
                onClick={() => {
                  setEditedData(row);
                  handleEditModalOpen(true);
                }}
              >
                <span
                  className={`align-items-center d-flex justify-content-center pe-1  `}
                >
                  <FaUserEdit />
                </span>
                <span>Edit</span>
              </div>

              <div
                className="d-flex justify-content-center align-items-center bg-danger text-white rounded-3 p-2 mx-2 "
                role="button"
                onClick={() => {
                  setEditedData(row);
                  handleDeleteModalOpen();
                }}
              >
                <span className={`d-flex align-items-center pe-1`}>
                  <MdDeleteForever />
                </span>
                <span>Delete</span>
              </div>
            </div>
          );
        }
      },
    },
  ];

  return (
    <div className={styles.MainContainer}>
      {deleteModal ? (
        <Suspense fallback={<Spinner animation="grow" size="sm" />}>
          <DeleteModal
            handleDeleteModalClose={handleDeleteModalClose}
            data={editedData}
            isDeleting={isDeleting}
            onDelete={deleteData}
          />
        </Suspense>
      ) : (
        ""
      )}

      {editModal ? (
        <Suspense fallback={<Spinner animation="grow" size="sm" />}>
          <EditBusinessTypeModal
            handleEditModalClose={handleEditModalClose}
            editData={editedData}
            refetch={refetch}
          />
        </Suspense>
      ) : (
        ""
      )}

      <div className={styles.headerContainer}>
        <div>
          <SubTitle text={"Business Types"} classes="text-black" />
        </div>

        {
          <Row>
            <Col md={12}>
              <Button
                classes={`${styles.cardBtn} `}
                text="Add Type"
                onClick={handleAddUser}
              ></Button>
            </Col>
          </Row>
        }
      </div>

      <div className={styles.mainTable}>
      {!dataFetched || !isSuccess ? (
          <CustomLoader />
        ) : (
        <div className={styles.mainTable}>
          {<Table data={rowData} columns={columns} indication={indication}
          
          totalRecords={totalRecords} // todo
          totalPages={totalPages} // todo
          pageSize={pageSize} // todo
          currentPage={currentPage} // todo
          setCurrentPage={setCurrentPage} // todo
          setPageSize={setPageSize} // todo
          refetch={refetch} // todo
          />}
        </div>
      )}
      </div>
    </div>
  );
};

export default BusinessTypeManagementScreen;
