import React from "react";

const NameImageComponent = ({ name, image }) => {
  return (
    <div className="d-flex flex-row justify-content-start align-items-center gap-2">
      <img
        alt="user"
        src={image}
        loading="lazy"
        width={48}
        height={48}
        className="rounded-circle object-fit-cover"
      />
      <div className="lc-1"> {`${name}`} </div>
    </div>
  );
};

export default NameImageComponent;
