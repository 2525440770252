import React, { lazy, Suspense } from "react";
import { Col, Row } from "react-bootstrap";
import { SubTitle } from "../../Components/CustomComponents";
import styles from "./styles/Dashboard.module.scss";
// import MatchCard from '../../Components/Cards/MatchCard';
// import TournamentCard from '../../Components/Cards/TournamentCard';

import DashboardCard from "../../Components/Cards/DashboardCard";
// Icons
import _ from "lodash";
import { AiOutlineFileAdd, AiOutlineFileDone } from "react-icons/ai";
import { FaHandHoldingDollar } from "react-icons/fa6";
import { LuBike } from "react-icons/lu";
import { MdEmojiPeople, MdRestaurantMenu } from "react-icons/md";
import { useSelector } from "react-redux";
import ClipLoader from "react-spinners/ClipLoader.js";
// lazy import
const LazyBarChart = lazy(() => import("./BarChart.js"));

export default function Dashboard({ setPage }) {
  var data = useSelector((state) => state.dashboard.data);
  console.log(data);

  // const data = {
  //   dashboardStats: {
  //     total_customers: 31,
  //     total_restaurants: 37,
  //     total_riders: 11,
  //     total_completed_orders: 44,
  //     total_ongoing_orders: 1,
  //     total_earnings: 230,
  //   },
  //   dashboardGraphData: [
  //     {
  //       month: "2024-06",
  //       total_earning: 110,
  //     },
  //     {
  //       month: "2024-07",
  //       total_earning: 120,
  //     },
  //     {
  //       month: "2024-08",
  //       total_earning: 100,
  //     },
  //     {
  //       month: "2024-09",
  //       total_earning: 80,
  //     },
  //     {
  //       month: "2024-10",
  //       total_earning: 210,
  //     },
  //     {
  //       month: "2024-11",
  //       total_earning: 20,
  //     },
  //   ],
  // };

  return (
    <div className={styles.Dashboard}>
      <div className={styles.headerContainer}>
        <div className={styles.titleContainer}>
          <SubTitle text={"Dashboard"} classes="text-black" />
        </div>
      </div>

      <Row className={`${styles.matchRow} w-100 mt-2 mb-5`}>
        <Col className={`mt-2 mb-2`} lg={4} md={4} sm={6}>
          <DashboardCard
            bg1={"#19619DFF"}
            bg2={"#1C75BC"}
            icon={<MdEmojiPeople color="#19619DFF" />}
            number={data?.dashboardStats?.total_customers}
            text={"Numbers of Customer"}
          />
        </Col>
        <Col className={`mt-2 mb-2`} lg={4} md={4} sm={6}>
          <DashboardCard
            bg1={"#19619DFF"}
            bg2={"#1C75BC"}
            icon={<MdRestaurantMenu color="#19619DFF" />}
            number={data?.dashboardStats?.total_restaurants}
            text={"Numbers of Restaurants"}
          />
        </Col>
        <Col className={`mt-2 mb-2`} lg={4} md={4} sm={6}>
          <DashboardCard
            bg1={"#19619DFF"}
            bg2={"#1C75BC"}
            icon={<LuBike color="#19619DFF" />}
            number={data?.dashboardStats?.total_riders}
            text={"Numbers of Riders"}
          />
        </Col>
        <Col className={`mt-2 mb-2`} lg={4} md={4} sm={6}>
          <DashboardCard
            bg1={"#19619DFF"}
            bg2={"#1C75BC"}
            icon={<AiOutlineFileDone color="#19619DFF" />}
            number={data?.dashboardStats?.total_completed_orders}
            text={"Numbers of Completed Orders"}
          />
        </Col>
        <Col className={`mt-2 mb-2`} lg={4} md={4} sm={12}>
          <DashboardCard
            bg1={"#19619DFF"}
            bg2={"#1C75BC"}
            icon={<AiOutlineFileAdd color="#19619DFF" />}
            number={data?.dashboardStats?.total_ongoing_orders}
            text={"Number of Ongoing Orders"}
          />
        </Col>
        <Col className={`mt-2 mb-2`} lg={4} md={4} sm={12}>
          <DashboardCard
            bg1={"#19619DFF"}
            bg2={"#1C75BC"}
            icon={<FaHandHoldingDollar color="#19619DFF" />}
            number={252}
            text={"Total Earnings"}
          />
        </Col>
      </Row>
      <div>
        {_.isEmpty(data?.dashboardGraphData) ? (
          <div className="d-flex justify-content-center">
            <ClipLoader color="#1C75BC" />
          </div>
        ) : (
          <Suspense>
            <LazyBarChart chartData={data?.dashboardGraphData} />
          </Suspense>
        )}
      </div>
    </div>
  );
}
