import axios from "axios";
import React, { lazy, Suspense, useCallback, useEffect, useState } from "react";
import "react-bootstrap-table-next/dist/react-bootstrap-table2.min.css";
import "react-bootstrap-table2-toolkit/dist/react-bootstrap-table2-toolkit.min.css";
import { IoMdEye } from "react-icons/io";
import { Input, SubTitle } from "../../Components/CustomComponents/index.js";
import Table from "../../Components/Table/Table.js";
import Toast from "../../Hooks/useToast.js";
import styles from "./styles/user_management.module.scss";
//
import { debounce } from "lodash";
import moment from "moment/moment.js";
import { Badge, Col, OverlayTrigger, Row, Spinner, Tooltip } from "react-bootstrap";
import { useDispatch } from "react-redux"; // for getting and setting data into
import NameImageComponent from "../../Components/CustomComponents/NameImageComponent.js";
import { OrderStatus } from "../../constants.js";
import { useGetAllOrdersQuery } from "../../Data/services/ordersApi.js";
import CustomLoader from "../../Components/CustomComponents/CustomLoader.js";

const OrderDetailModal = lazy(() =>
  import("../../Components/Modals/OrderDetailModal.js")
);
// const NameImageComponent = lazy(() =>
//   import("../../Components/CustomComponents/NameImageComponent.js")
// );

const dummy_data = [
  {
    order_id: "# 78",
    slug: "30518bc8-70c9-47f0-a249-5e627a29b123",
    restaurant_slug: "cbc3d19f-5e48-4bcd-9c88-0e54b65bc124",
    order_items: [
      {
        slug: "85964980-1141-4991-9e43-b142e9486c61",
        order_slug: "30518bc8-70c9-47f0-a249-5e627a29b123",
        item_slug: "fe162a7f-696d-4b39-82f9-33fdb4be65f1",
        quantity: 1,
        price: 25,
        special_request: "Please put extra ketup in it",
        createdAt: "2024-11-21T11:56:17.000Z",
        restaurant_menu: {
          slug: "fe162a7f-696d-4b39-82f9-33fdb4be65f1",
          restaurant_slug: "cbc3d19f-5e48-4bcd-9c88-0e54b65bc124",
          item_name: "Coffee and Fried Rice",
          item_description: "A Japanese Coffee with Rice",
          item_image:
            "http://api.wow.trangotechdevs.com:3011/1731491338257.jpg",
          createdAt: "2024-11-13T09:48:58.000Z",
        },
        order_ingredient_items: [],
      },
    ],
    subtotal: 25,
    delivery_charges: 10,
    platform_fee: 5,
    gst: 2.5,
    payment_method: null,
    restaurant: {
      image_url: "https://api.wow.trangotechdevs.com/1731491430146.jpg",
      slug: "cbc3d19f-5e48-4bcd-9c88-0e54b65bc124",
      firstname: null,
      lastname: null,
      name: "Forever Found",
      mobile_no: "+233263596433",
      latitude: null,
      longitude: null,
      location: null,
    },
    user: {
      image_url:
        "http://api.wow.trangotechdevs.com:3011/1728033454593.octet-stream",
      slug: "c84d9188-c726-49d0-a292-7987e6152f77",
      firstname: "customer",
      lastname: "one",
      name: null,
      mobile_no: "+11234567988",
      latitude: "24.882929",
      longitude: "67.069295",
      location: "",
    },
    total_quantity: 1,
    total_amount: 42.5,
    biker_tip: 0,
    location: "Karachi, Pakistan",
    latitude: "24.877838",
    longitude: "67.065945",
    order_status: 7,
    order_status_title: "Order Delivered",
    createdAt: "2024-11-21T11:56:17.000Z",
    pay_with: "paystack",
  },
  {
    order_id: "# 77",
    slug: "938cfbf1-6a78-4b61-b743-8549f438acad",
    restaurant_slug: "91a56791-552c-4028-a81f-98895f504a5e",
    order_items: [
      {
        slug: "db2f2a60-95bc-4e81-9808-f337f089c83c",
        order_slug: "938cfbf1-6a78-4b61-b743-8549f438acad",
        item_slug: "7a0d0262-de01-47ae-9383-c380385719e3",
        quantity: 1,
        price: 70,
        special_request: "test",
        createdAt: "2024-11-20T22:49:39.000Z",
        restaurant_menu: {
          slug: "7a0d0262-de01-47ae-9383-c380385719e3",
          restaurant_slug: "91a56791-552c-4028-a81f-98895f504a5e",
          item_name: "Jollof Rice with Chicken ",
          item_description: "1 Plate",
          item_image:
            "http://api.wow.trangotechdevs.com:3011/1722287157872.jpg",
          createdAt: "2024-07-29T21:05:57.000Z",
        },
        order_ingredient_items: [
          {
            slug: "77fa01a5-f8d9-4d4f-b47c-ef9870f99eb8",
            title: "Jollof with Chicken",
          },
        ],
      },
    ],
    subtotal: 70,
    delivery_charges: 10,
    platform_fee: 5,
    gst: 0,
    payment_method: null,
    restaurant: {
      image_url: "https://api.wow.trangotechdevs.com/1722286459977.jpg",
      slug: "91a56791-552c-4028-a81f-98895f504a5e",
      firstname: null,
      lastname: null,
      name: "Bossman's  Kitchen ",
      mobile_no: "+233532871143",
      latitude: null,
      longitude: null,
      location: null,
    },
    user: {
      image_url:
        "http://api.wow.trangotechdevs.com:3011/1729642576124.octet-stream",
      slug: "93c7088d-346e-45b4-bbae-db13a89b9900",
      firstname: "Sam",
      lastname: "Obeng",
      name: null,
      mobile_no: "+18483912393",
      latitude: null,
      longitude: null,
      location: null,
    },
    total_quantity: 1,
    total_amount: 105,
    biker_tip: 20,
    location: "1234 Plt again ",
    latitude: "6.663142979770264",
    longitude: "-1.5542717278003693",
    order_status: 8,
    order_status_title: "Completed",
    createdAt: "2024-11-20T22:49:39.000Z",
    pay_with: null,
  },
  {
    order_id: "# 76",
    slug: "a4c4ea76-40e2-4bf1-bed1-17733690c9a3",
    restaurant_slug: "d3529e7b-6c3c-4876-8785-4cd059c9962d",
    order_items: [
      {
        slug: "e7b1dce5-d407-4aa6-a5cf-cd51d70b1fbe",
        order_slug: "a4c4ea76-40e2-4bf1-bed1-17733690c9a3",
        item_slug: "1e9d1971-59ab-48a7-8e56-5e6bda1b0d57",
        quantity: 1,
        price: 10,
        special_request: "test",
        createdAt: "2024-11-20T12:46:45.000Z",
        restaurant_menu: {
          slug: "1e9d1971-59ab-48a7-8e56-5e6bda1b0d57",
          restaurant_slug: "d3529e7b-6c3c-4876-8785-4cd059c9962d",
          item_name: "FUFU WITH ABUNABUNU",
          item_description:
            "Special Ghanaian delicacy ( Fufu With Green Soup, snail, Crab and Fish )",
          item_image:
            "http://api.wow.trangotechdevs.com:3011/1722692695080.jpg",
          createdAt: "2024-08-03T13:44:55.000Z",
        },
        order_ingredient_items: [],
      },
      {
        slug: "952da9c4-b732-43ab-9c92-0bf79138f74f",
        order_slug: "a4c4ea76-40e2-4bf1-bed1-17733690c9a3",
        item_slug: "669be0f4-1f87-4e1b-8a5b-c931ee150f26",
        quantity: 1,
        price: 25,
        special_request: "test",
        createdAt: "2024-11-20T12:46:45.000Z",
        restaurant_menu: {
          slug: "669be0f4-1f87-4e1b-8a5b-c931ee150f26",
          restaurant_slug: "d3529e7b-6c3c-4876-8785-4cd059c9962d",
          item_name: "BANKU WITH TILAPIA ",
          item_description:
            "Special BANKU with Tilapia made with natural ingredients.",
          item_image:
            "http://api.wow.trangotechdevs.com:3011/1722693020337.jpg",
          createdAt: "2024-08-03T13:50:20.000Z",
        },
        order_ingredient_items: [
          {
            slug: "5cf2b6da-0556-4e2b-9675-1541d3d24f79",
            title: "Cassava Fish",
          },
        ],
      },
    ],
    subtotal: 35,
    delivery_charges: 10,
    platform_fee: 5,
    gst: 0,
    payment_method: null,
    restaurant: {
      image_url: "https://api.wow.trangotechdevs.com/1722691957121.jpg",
      slug: "d3529e7b-6c3c-4876-8785-4cd059c9962d",
      firstname: null,
      lastname: null,
      name: "CALCULUS' LOCAL FOODS",
      mobile_no: "+233249742680",
      latitude: null,
      longitude: null,
      location: null,
    },
    user: {
      image_url: "https://api.wow.trangotechdevs.com/user-placeholder.jpeg",
      slug: "690be2c6-21e8-435d-b245-939f0b60678c",
      firstname: null,
      lastname: null,
      name: "KWAKU NTIAMOAH",
      mobile_no: null,
      latitude: null,
      longitude: null,
      location: null,
    },
    total_quantity: 2,
    total_amount: 70,
    biker_tip: 20,
    location: "Kumasi ",
    latitude: "6.6424245649965945",
    longitude: "-1.5902331098914146",
    order_status: 1,
    order_status_title: "Unaccepted Order",
    createdAt: "2024-11-20T12:46:45.000Z",
    pay_with: null,
  },
  {
    order_id: "# 75",
    slug: "fad6ef3f-9c6a-43ed-b692-419e644f962a",
    restaurant_slug: "91a56791-552c-4028-a81f-98895f504a5e",
    order_items: [
      {
        slug: "0c3e402c-bf8f-469a-9102-b831e38bc330",
        order_slug: "fad6ef3f-9c6a-43ed-b692-419e644f962a",
        item_slug: "7a0d0262-de01-47ae-9383-c380385719e3",
        quantity: 1,
        price: 70,
        special_request: "test",
        createdAt: "2024-11-20T02:07:46.000Z",
        restaurant_menu: {
          slug: "7a0d0262-de01-47ae-9383-c380385719e3",
          restaurant_slug: "91a56791-552c-4028-a81f-98895f504a5e",
          item_name: "Jollof Rice with Chicken ",
          item_description: "1 Plate",
          item_image:
            "http://api.wow.trangotechdevs.com:3011/1722287157872.jpg",
          createdAt: "2024-07-29T21:05:57.000Z",
        },
        order_ingredient_items: [
          {
            slug: "378f4098-2a6b-4da5-8a6b-13b1cf646e29",
            title: "Jollof with Chicken",
          },
        ],
      },
    ],
    subtotal: 70,
    delivery_charges: 10,
    platform_fee: 5,
    gst: 0,
    payment_method: null,
    restaurant: {
      image_url: "https://api.wow.trangotechdevs.com/1722286459977.jpg",
      slug: "91a56791-552c-4028-a81f-98895f504a5e",
      firstname: null,
      lastname: null,
      name: "Bossman's  Kitchen ",
      mobile_no: "+233532871143",
      latitude: null,
      longitude: null,
      location: null,
    },
    user: {
      image_url:
        "http://api.wow.trangotechdevs.com:3011/1729642576124.octet-stream",
      slug: "93c7088d-346e-45b4-bbae-db13a89b9900",
      firstname: "Sam",
      lastname: "Obeng",
      name: null,
      mobile_no: "+18483912393",
      latitude: null,
      longitude: null,
      location: null,
    },
    total_quantity: 1,
    total_amount: 105,
    biker_tip: 20,
    location: "1234 Plt again ",
    latitude: "6.663142979770264",
    longitude: "-1.5542717278003693",
    order_status: 8,
    order_status_title: "Completed",
    createdAt: "2024-11-20T02:07:46.000Z",
    pay_with: null,
  },
  {
    order_id: "# 74",
    slug: "26d2b0bd-1fc2-4e9d-9098-a7f2dc72d2b6",
    restaurant_slug: "9a027016-4cfa-42fa-95bc-18e52b6fe4a2",
    order_items: [
      {
        slug: "9b526bfe-c8ba-4845-818d-9fdf088cf5b4",
        order_slug: "26d2b0bd-1fc2-4e9d-9098-a7f2dc72d2b6",
        item_slug: "e626d2b5-569c-4520-b348-b076c1344f5e",
        quantity: 1,
        price: 50,
        special_request: "test",
        createdAt: "2024-10-21T07:51:55.000Z",
        restaurant_menu: {
          slug: "e626d2b5-569c-4520-b348-b076c1344f5e",
          restaurant_slug: "9a027016-4cfa-42fa-95bc-18e52b6fe4a2",
          item_name: "Chicken",
          item_description: "test test test",
          item_image:
            "http://api.wow.trangotechdevs.com:3011/1722328274941.jpg",
          createdAt: "2024-07-30T08:31:14.000Z",
        },
        order_ingredient_items: [],
      },
    ],
    subtotal: 50,
    delivery_charges: 10,
    platform_fee: 5,
    gst: 5,
    payment_method: null,
    restaurant: {
      image_url: "https://api.wow.trangotechdevs.com/1726831869130.jpg",
      slug: "9a027016-4cfa-42fa-95bc-18e52b6fe4a2",
      firstname: null,
      lastname: null,
      name: "restautrant1",
      mobile_no: "+11234567898",
      latitude: "24.882929",
      longitude: "67.069295",
      location: "",
    },
    user: {
      image_url:
        "http://api.wow.trangotechdevs.com:3011/1728033454593.octet-stream",
      slug: "c84d9188-c726-49d0-a292-7987e6152f77",
      firstname: "customer",
      lastname: "one",
      name: null,
      mobile_no: "+11234567988",
      latitude: "24.882929",
      longitude: "67.069295",
      location: "",
    },
    total_quantity: 1,
    total_amount: 90,
    biker_tip: 20,
    location: "House test 123 abc,abc",
    latitude: "24.878128909752306",
    longitude: "67.06904011392183",
    order_status: 8,
    order_status_title: "Completed",
    createdAt: "2024-10-21T07:51:55.000Z",
    pay_with: null,
  },
  {
    order_id: "# 73",
    slug: "760c5950-e0de-4575-9d38-49405e93a0d4",
    restaurant_slug: "9a027016-4cfa-42fa-95bc-18e52b6fe4a2",
    order_items: [
      {
        slug: "f6b80df6-c217-4fc5-bfa4-e7f9beee2654",
        order_slug: "760c5950-e0de-4575-9d38-49405e93a0d4",
        item_slug: "e626d2b5-569c-4520-b348-b076c1344f5e",
        quantity: 1,
        price: 50,
        special_request: "test",
        createdAt: "2024-10-18T14:50:46.000Z",
        restaurant_menu: {
          slug: "e626d2b5-569c-4520-b348-b076c1344f5e",
          restaurant_slug: "9a027016-4cfa-42fa-95bc-18e52b6fe4a2",
          item_name: "Chicken",
          item_description: "test test test",
          item_image:
            "http://api.wow.trangotechdevs.com:3011/1722328274941.jpg",
          createdAt: "2024-07-30T08:31:14.000Z",
        },
        order_ingredient_items: [
          {
            slug: "3580d356-662d-4187-acce-97ac60c227c8",
            title: "Water",
          },
          {
            slug: "c9214043-4bad-4a8d-a3f2-bbdebd9b0e19",
            title: "Garlic mayo",
          },
        ],
      },
    ],
    subtotal: 50,
    delivery_charges: 10,
    platform_fee: 5,
    gst: 5,
    payment_method: null,
    restaurant: {
      image_url: "https://api.wow.trangotechdevs.com/1726831869130.jpg",
      slug: "9a027016-4cfa-42fa-95bc-18e52b6fe4a2",
      firstname: null,
      lastname: null,
      name: "restautrant1",
      mobile_no: "+11234567898",
      latitude: "24.882929",
      longitude: "67.069295",
      location: "",
    },
    user: {
      image_url:
        "http://api.wow.trangotechdevs.com:3011/1728033454593.octet-stream",
      slug: "c84d9188-c726-49d0-a292-7987e6152f77",
      firstname: "customer",
      lastname: "one",
      name: null,
      mobile_no: "+11234567988",
      latitude: "24.882929",
      longitude: "67.069295",
      location: "",
    },
    total_quantity: 1,
    total_amount: 90,
    biker_tip: 20,
    location: "House test 123 abc,abc",
    latitude: "24.878128909752306",
    longitude: "67.06904011392183",
    order_status: 8,
    order_status_title: "Completed",
    createdAt: "2024-10-18T14:50:46.000Z",
    pay_with: null,
  },
  {
    order_id: "# 72",
    slug: "26561aa2-50d7-4944-8cd3-03a8430ab39e",
    restaurant_slug: "9a027016-4cfa-42fa-95bc-18e52b6fe4a2",
    order_items: [
      {
        slug: "a140910e-ab9e-43c2-80fd-89315b7a744e",
        order_slug: "26561aa2-50d7-4944-8cd3-03a8430ab39e",
        item_slug: "6afd79cd-7435-499a-b7a5-40caecbe5111",
        quantity: 1,
        price: 17.95,
        special_request: "test",
        createdAt: "2024-10-18T14:45:00.000Z",
        restaurant_menu: {
          slug: "6afd79cd-7435-499a-b7a5-40caecbe5111",
          restaurant_slug: "9a027016-4cfa-42fa-95bc-18e52b6fe4a2",
          item_name: "Burger",
          item_description: "Freshly caught flounder sautéed to perfection",
          item_image:
            "http://api.wow.trangotechdevs.com:3011/1721896455732.jpeg",
          createdAt: "2024-07-25T08:34:15.000Z",
        },
        order_ingredient_items: [
          {
            slug: "8a0be031-f069-4672-bb39-a6fb42303a85",
            title: "Lemon Sauce",
          },
          {
            slug: "05a5e612-ac4a-4ceb-b65b-4429cdad0869",
            title: "French Fries",
          },
          {
            slug: "c4d9d5df-01db-4909-a418-67a6d7f1c115",
            title: "Grilled Vegetables",
          },
        ],
      },
    ],
    subtotal: 17.95,
    delivery_charges: 10,
    platform_fee: 5,
    gst: 1.8,
    payment_method: null,
    restaurant: {
      image_url: "https://api.wow.trangotechdevs.com/1726831869130.jpg",
      slug: "9a027016-4cfa-42fa-95bc-18e52b6fe4a2",
      firstname: null,
      lastname: null,
      name: "restautrant1",
      mobile_no: "+11234567898",
      latitude: "24.882929",
      longitude: "67.069295",
      location: "",
    },
    user: {
      image_url:
        "http://api.wow.trangotechdevs.com:3011/1728033454593.octet-stream",
      slug: "c84d9188-c726-49d0-a292-7987e6152f77",
      firstname: "customer",
      lastname: "one",
      name: null,
      mobile_no: "+11234567988",
      latitude: "24.882929",
      longitude: "67.069295",
      location: "",
    },
    total_quantity: 1,
    total_amount: 54.75,
    biker_tip: 20,
    location: "House test 123 abc,abc",
    latitude: "24.878128909752306",
    longitude: "67.06904011392183",
    order_status: 8,
    order_status_title: "Completed",
    createdAt: "2024-10-18T14:45:00.000Z",
    pay_with: null,
  },
  {
    order_id: "# 71",
    slug: "f2c78050-02c5-4d07-a835-de2c0018dba0",
    restaurant_slug: "9a027016-4cfa-42fa-95bc-18e52b6fe4a2",
    order_items: [
      {
        slug: "e6753de9-2118-4ac4-870b-a3d8ebec5241",
        order_slug: "f2c78050-02c5-4d07-a835-de2c0018dba0",
        item_slug: "6afd79cd-7435-499a-b7a5-40caecbe5111",
        quantity: 1,
        price: 17.95,
        special_request: "test",
        createdAt: "2024-10-18T14:33:25.000Z",
        restaurant_menu: {
          slug: "6afd79cd-7435-499a-b7a5-40caecbe5111",
          restaurant_slug: "9a027016-4cfa-42fa-95bc-18e52b6fe4a2",
          item_name: "Burger",
          item_description: "Freshly caught flounder sautéed to perfection",
          item_image:
            "http://api.wow.trangotechdevs.com:3011/1721896455732.jpeg",
          createdAt: "2024-07-25T08:34:15.000Z",
        },
        order_ingredient_items: [],
      },
    ],
    subtotal: 17.95,
    delivery_charges: 10,
    platform_fee: 5,
    gst: 1.8,
    payment_method: null,
    restaurant: {
      image_url: "https://api.wow.trangotechdevs.com/1726831869130.jpg",
      slug: "9a027016-4cfa-42fa-95bc-18e52b6fe4a2",
      firstname: null,
      lastname: null,
      name: "restautrant1",
      mobile_no: "+11234567898",
      latitude: "24.882929",
      longitude: "67.069295",
      location: "",
    },
    user: {
      image_url:
        "http://api.wow.trangotechdevs.com:3011/1728033454593.octet-stream",
      slug: "c84d9188-c726-49d0-a292-7987e6152f77",
      firstname: "customer",
      lastname: "one",
      name: null,
      mobile_no: "+11234567988",
      latitude: "24.882929",
      longitude: "67.069295",
      location: "",
    },
    total_quantity: 1,
    total_amount: 54.75,
    biker_tip: 20,
    location: "House test 123 abc,abc",
    latitude: "24.878128909752306",
    longitude: "67.06904011392183",
    order_status: 8,
    order_status_title: "Completed",
    createdAt: "2024-10-18T14:33:25.000Z",
    pay_with: null,
  },
  {
    order_id: "# 70",
    slug: "714f23de-daac-4adb-b5e2-a5d4e25c09d8",
    restaurant_slug: "9a027016-4cfa-42fa-95bc-18e52b6fe4a2",
    order_items: [
      {
        slug: "327082c1-05dc-4f2d-b12b-7257881f9094",
        order_slug: "714f23de-daac-4adb-b5e2-a5d4e25c09d8",
        item_slug: "6afd79cd-7435-499a-b7a5-40caecbe5111",
        quantity: 1,
        price: 17.95,
        special_request: "test",
        createdAt: "2024-10-18T12:26:42.000Z",
        restaurant_menu: {
          slug: "6afd79cd-7435-499a-b7a5-40caecbe5111",
          restaurant_slug: "9a027016-4cfa-42fa-95bc-18e52b6fe4a2",
          item_name: "Burger",
          item_description: "Freshly caught flounder sautéed to perfection",
          item_image:
            "http://api.wow.trangotechdevs.com:3011/1721896455732.jpeg",
          createdAt: "2024-07-25T08:34:15.000Z",
        },
        order_ingredient_items: [
          {
            slug: "60d118b3-2fb1-4907-9359-b294620835cc",
            title: "Lemon Sauce",
          },
          {
            slug: "b28f5969-4edf-4422-b62a-4ae4fa6b9b47",
            title: "Grilled Vegetables",
          },
        ],
      },
    ],
    subtotal: 17.95,
    delivery_charges: 10,
    platform_fee: 5,
    gst: 1.8,
    payment_method: null,
    restaurant: {
      image_url: "https://api.wow.trangotechdevs.com/1726831869130.jpg",
      slug: "9a027016-4cfa-42fa-95bc-18e52b6fe4a2",
      firstname: null,
      lastname: null,
      name: "restautrant1",
      mobile_no: "+11234567898",
      latitude: "24.882929",
      longitude: "67.069295",
      location: "",
    },
    user: {
      image_url:
        "http://api.wow.trangotechdevs.com:3011/1728033454593.octet-stream",
      slug: "c84d9188-c726-49d0-a292-7987e6152f77",
      firstname: "customer",
      lastname: "one",
      name: null,
      mobile_no: "+11234567988",
      latitude: "24.882929",
      longitude: "67.069295",
      location: "",
    },
    total_quantity: 1,
    total_amount: 54.75,
    biker_tip: 20,
    location: "House test 123 abc,abc",
    latitude: "24.878128909752306",
    longitude: "67.06904011392183",
    order_status: 8,
    order_status_title: "Completed",
    createdAt: "2024-10-18T12:26:42.000Z",
    pay_with: null,
  },
  {
    order_id: "# 69",
    slug: "57d1e70d-4b2e-454a-8800-0b449e9656e2",
    restaurant_slug: "9a027016-4cfa-42fa-95bc-18e52b6fe4a2",
    order_items: [
      {
        slug: "f7c67c23-ba4d-42af-ab29-125cf294e626",
        order_slug: "57d1e70d-4b2e-454a-8800-0b449e9656e2",
        item_slug: "6afd79cd-7435-499a-b7a5-40caecbe5111",
        quantity: 1,
        price: 17.95,
        special_request: "test",
        createdAt: "2024-10-18T12:22:03.000Z",
        restaurant_menu: {
          slug: "6afd79cd-7435-499a-b7a5-40caecbe5111",
          restaurant_slug: "9a027016-4cfa-42fa-95bc-18e52b6fe4a2",
          item_name: "Burger",
          item_description: "Freshly caught flounder sautéed to perfection",
          item_image:
            "http://api.wow.trangotechdevs.com:3011/1721896455732.jpeg",
          createdAt: "2024-07-25T08:34:15.000Z",
        },
        order_ingredient_items: [
          {
            slug: "9216cba5-b7d9-4036-81d7-d2f2d60bc2f7",
            title: "Lemon Sauce",
          },
          {
            slug: "fde1c4f7-6f9a-4d9d-b4a4-c0b226bde1bc",
            title: "Grilled Vegetables",
          },
        ],
      },
    ],
    subtotal: 17.95,
    delivery_charges: 10,
    platform_fee: 5,
    gst: 1.8,
    payment_method: null,
    restaurant: {
      image_url: "https://api.wow.trangotechdevs.com/1726831869130.jpg",
      slug: "9a027016-4cfa-42fa-95bc-18e52b6fe4a2",
      firstname: null,
      lastname: null,
      name: "restautrant1",
      mobile_no: "+11234567898",
      latitude: "24.882929",
      longitude: "67.069295",
      location: "",
    },
    user: {
      image_url:
        "http://api.wow.trangotechdevs.com:3011/1728033454593.octet-stream",
      slug: "c84d9188-c726-49d0-a292-7987e6152f77",
      firstname: "customer",
      lastname: "one",
      name: null,
      mobile_no: "+11234567988",
      latitude: "24.882929",
      longitude: "67.069295",
      location: "",
    },
    total_quantity: 1,
    total_amount: 54.75,
    biker_tip: 20,
    location: "House test 123 abc,abc",
    latitude: "24.878128909752306",
    longitude: "67.06904011392183",
    order_status: 8,
    order_status_title: "Completed",
    createdAt: "2024-10-18T12:22:03.000Z",
    pay_with: null,
  },
  {
    order_id: "# 68",
    slug: "a13dbed2-cd20-4471-99c1-e8d8c00e3fb5",
    restaurant_slug: "9a027016-4cfa-42fa-95bc-18e52b6fe4a2",
    order_items: [
      {
        slug: "be4017f0-de5b-41b0-914a-79b4248bb765",
        order_slug: "a13dbed2-cd20-4471-99c1-e8d8c00e3fb5",
        item_slug: "6afd79cd-7435-499a-b7a5-40caecbe5111",
        quantity: 1,
        price: 17.95,
        special_request: "test",
        createdAt: "2024-10-17T13:56:01.000Z",
        restaurant_menu: {
          slug: "6afd79cd-7435-499a-b7a5-40caecbe5111",
          restaurant_slug: "9a027016-4cfa-42fa-95bc-18e52b6fe4a2",
          item_name: "Burger",
          item_description: "Freshly caught flounder sautéed to perfection",
          item_image:
            "http://api.wow.trangotechdevs.com:3011/1721896455732.jpeg",
          createdAt: "2024-07-25T08:34:15.000Z",
        },
        order_ingredient_items: [
          {
            slug: "a915ba34-5d7e-4da9-ac08-968cc7a012f0",
            title: "French Fries",
          },
          {
            slug: "632df7fb-3dcd-4c5e-a914-cc0d74398722",
            title: "No Sauce",
          },
        ],
      },
    ],
    subtotal: 17.95,
    delivery_charges: 10,
    platform_fee: 5,
    gst: 1.8,
    payment_method: null,
    restaurant: {
      image_url: "https://api.wow.trangotechdevs.com/1726831869130.jpg",
      slug: "9a027016-4cfa-42fa-95bc-18e52b6fe4a2",
      firstname: null,
      lastname: null,
      name: "restautrant1",
      mobile_no: "+11234567898",
      latitude: "24.882929",
      longitude: "67.069295",
      location: "",
    },
    user: {
      image_url:
        "http://api.wow.trangotechdevs.com:3011/1728033454593.octet-stream",
      slug: "c84d9188-c726-49d0-a292-7987e6152f77",
      firstname: "customer",
      lastname: "one",
      name: null,
      mobile_no: "+11234567988",
      latitude: "24.882929",
      longitude: "67.069295",
      location: "",
    },
    total_quantity: 1,
    total_amount: 54.75,
    biker_tip: 20,
    location: "House test 123 abc,abc",
    latitude: "24.878128909752306",
    longitude: "67.06904011392183",
    order_status: 8,
    order_status_title: "Completed",
    createdAt: "2024-10-17T13:56:01.000Z",
    pay_with: null,
  },
  {
    order_id: "# 67",
    slug: "8d17ff71-472b-4b6d-970a-5a28680dccb6",
    restaurant_slug: "9a027016-4cfa-42fa-95bc-18e52b6fe4a2",
    order_items: [
      {
        slug: "87a977fb-b2d5-42d9-a0c2-32ddbc38d7cb",
        order_slug: "8d17ff71-472b-4b6d-970a-5a28680dccb6",
        item_slug: "6afd79cd-7435-499a-b7a5-40caecbe5111",
        quantity: 1,
        price: 17.95,
        special_request: "test",
        createdAt: "2024-10-17T12:57:42.000Z",
        restaurant_menu: {
          slug: "6afd79cd-7435-499a-b7a5-40caecbe5111",
          restaurant_slug: "9a027016-4cfa-42fa-95bc-18e52b6fe4a2",
          item_name: "Burger",
          item_description: "Freshly caught flounder sautéed to perfection",
          item_image:
            "http://api.wow.trangotechdevs.com:3011/1721896455732.jpeg",
          createdAt: "2024-07-25T08:34:15.000Z",
        },
        order_ingredient_items: [
          {
            slug: "09ccfa88-cc12-4a1e-b1fb-5a817d5054e7",
            title: "Garlic Butter",
          },
          {
            slug: "0d1a0643-8308-4a7d-93aa-a6ec90c2d559",
            title: "French Fries",
          },
          {
            slug: "12bd34ef-1953-4074-8472-c7ee43bf341a",
            title: "Grilled Vegetables",
          },
        ],
      },
    ],
    subtotal: 17.95,
    delivery_charges: 10,
    platform_fee: 5,
    gst: 1.8,
    payment_method: null,
    restaurant: {
      image_url: "https://api.wow.trangotechdevs.com/1726831869130.jpg",
      slug: "9a027016-4cfa-42fa-95bc-18e52b6fe4a2",
      firstname: null,
      lastname: null,
      name: "restautrant1",
      mobile_no: "+11234567898",
      latitude: "24.882929",
      longitude: "67.069295",
      location: "",
    },
    user: {
      image_url:
        "http://api.wow.trangotechdevs.com:3011/1728033454593.octet-stream",
      slug: "c84d9188-c726-49d0-a292-7987e6152f77",
      firstname: "customer",
      lastname: "one",
      name: null,
      mobile_no: "+11234567988",
      latitude: "24.882929",
      longitude: "67.069295",
      location: "",
    },
    total_quantity: 1,
    total_amount: 54.75,
    biker_tip: 20,
    location: "House test 123 abc,abc",
    latitude: "24.878128909752306",
    longitude: "67.06904011392183",
    order_status: 8,
    order_status_title: "Completed",
    createdAt: "2024-10-17T12:57:42.000Z",
    pay_with: null,
  },
  {
    order_id: "# 66",
    slug: "7e4fb331-7a6c-4914-8142-ba7cbb8792a6",
    restaurant_slug: "9a027016-4cfa-42fa-95bc-18e52b6fe4a2",
    order_items: [
      {
        slug: "ec5cefb9-38e4-457c-8a7b-7905e5bed227",
        order_slug: "7e4fb331-7a6c-4914-8142-ba7cbb8792a6",
        item_slug: "6afd79cd-7435-499a-b7a5-40caecbe5111",
        quantity: 1,
        price: 17.95,
        special_request: "test",
        createdAt: "2024-10-16T13:07:37.000Z",
        restaurant_menu: {
          slug: "6afd79cd-7435-499a-b7a5-40caecbe5111",
          restaurant_slug: "9a027016-4cfa-42fa-95bc-18e52b6fe4a2",
          item_name: "Burger",
          item_description: "Freshly caught flounder sautéed to perfection",
          item_image:
            "http://api.wow.trangotechdevs.com:3011/1721896455732.jpeg",
          createdAt: "2024-07-25T08:34:15.000Z",
        },
        order_ingredient_items: [
          {
            slug: "8ab6738e-b556-4032-a92b-b889d45038b1",
            title: "Garlic Butter",
          },
          {
            slug: "6008cb94-73e1-4561-8d18-9ca69ee48030",
            title: "Grilled Vegetables",
          },
        ],
      },
    ],
    subtotal: 17.95,
    delivery_charges: 10,
    platform_fee: 5,
    gst: 1.8,
    payment_method: null,
    restaurant: {
      image_url: "https://api.wow.trangotechdevs.com/1726831869130.jpg",
      slug: "9a027016-4cfa-42fa-95bc-18e52b6fe4a2",
      firstname: null,
      lastname: null,
      name: "restautrant1",
      mobile_no: "+11234567898",
      latitude: "24.882929",
      longitude: "67.069295",
      location: "",
    },
    user: {
      image_url:
        "http://api.wow.trangotechdevs.com:3011/1728033454593.octet-stream",
      slug: "c84d9188-c726-49d0-a292-7987e6152f77",
      firstname: "customer",
      lastname: "one",
      name: null,
      mobile_no: "+11234567988",
      latitude: "24.882929",
      longitude: "67.069295",
      location: "",
    },
    total_quantity: 1,
    total_amount: 54.75,
    biker_tip: 20,
    location: "House test 123 abc,abc",
    latitude: "24.878128909752306",
    longitude: "67.06904011392183",
    order_status: 8,
    order_status_title: "Completed",
    createdAt: "2024-10-16T13:07:37.000Z",
    pay_with: null,
  },
  {
    order_id: "# 65",
    slug: "ac2eb4a4-a3ae-47f2-8084-52513cf685ea",
    restaurant_slug: "9a027016-4cfa-42fa-95bc-18e52b6fe4a2",
    order_items: [
      {
        slug: "2357d864-4748-4462-9e69-74fc2c603e4b",
        order_slug: "ac2eb4a4-a3ae-47f2-8084-52513cf685ea",
        item_slug: "6afd79cd-7435-499a-b7a5-40caecbe5111",
        quantity: 1,
        price: 17.95,
        special_request: "test",
        createdAt: "2024-10-16T12:46:35.000Z",
        restaurant_menu: {
          slug: "6afd79cd-7435-499a-b7a5-40caecbe5111",
          restaurant_slug: "9a027016-4cfa-42fa-95bc-18e52b6fe4a2",
          item_name: "Burger",
          item_description: "Freshly caught flounder sautéed to perfection",
          item_image:
            "http://api.wow.trangotechdevs.com:3011/1721896455732.jpeg",
          createdAt: "2024-07-25T08:34:15.000Z",
        },
        order_ingredient_items: [
          {
            slug: "9a0bf746-ede7-4238-8609-b2f8cade8cbb",
            title: "Lemon Sauce",
          },
          {
            slug: "13a2e171-1d1c-4809-be64-e6e2775d5963",
            title: "Grilled Vegetables",
          },
        ],
      },
    ],
    subtotal: 17.95,
    delivery_charges: 10,
    platform_fee: 5,
    gst: 1.8,
    payment_method: null,
    restaurant: {
      image_url: "https://api.wow.trangotechdevs.com/1726831869130.jpg",
      slug: "9a027016-4cfa-42fa-95bc-18e52b6fe4a2",
      firstname: null,
      lastname: null,
      name: "restautrant1",
      mobile_no: "+11234567898",
      latitude: "24.882929",
      longitude: "67.069295",
      location: "",
    },
    user: {
      image_url:
        "http://api.wow.trangotechdevs.com:3011/1728033454593.octet-stream",
      slug: "c84d9188-c726-49d0-a292-7987e6152f77",
      firstname: "customer",
      lastname: "one",
      name: null,
      mobile_no: "+11234567988",
      latitude: "24.882929",
      longitude: "67.069295",
      location: "",
    },
    total_quantity: 1,
    total_amount: 54.75,
    biker_tip: 20,
    location: "House test 123 abc,abc",
    latitude: "24.878128909752306",
    longitude: "67.06904011392183",
    order_status: 8,
    order_status_title: "Completed",
    createdAt: "2024-10-16T12:46:35.000Z",
    pay_with: null,
  },
  {
    order_id: "# 64",
    slug: "75881479-0ed3-40ba-9e24-785d606514d9",
    restaurant_slug: "9a027016-4cfa-42fa-95bc-18e52b6fe4a2",
    order_items: [
      {
        slug: "64a48166-978e-40f8-a312-bb5568680c2b",
        order_slug: "75881479-0ed3-40ba-9e24-785d606514d9",
        item_slug: "6afd79cd-7435-499a-b7a5-40caecbe5111",
        quantity: 1,
        price: 17.95,
        special_request: "test",
        createdAt: "2024-10-16T12:38:42.000Z",
        restaurant_menu: {
          slug: "6afd79cd-7435-499a-b7a5-40caecbe5111",
          restaurant_slug: "9a027016-4cfa-42fa-95bc-18e52b6fe4a2",
          item_name: "Burger",
          item_description: "Freshly caught flounder sautéed to perfection",
          item_image:
            "http://api.wow.trangotechdevs.com:3011/1721896455732.jpeg",
          createdAt: "2024-07-25T08:34:15.000Z",
        },
        order_ingredient_items: [
          {
            slug: "132fafb9-979f-423e-8ef4-55140b50748b",
            title: "Garlic Butter",
          },
          {
            slug: "412828bc-3c3e-4e38-a74d-736821288a7c",
            title: "Grilled Vegetables",
          },
        ],
      },
    ],
    subtotal: 17.95,
    delivery_charges: 10,
    platform_fee: 5,
    gst: 1.8,
    payment_method: null,
    restaurant: {
      image_url: "https://api.wow.trangotechdevs.com/1726831869130.jpg",
      slug: "9a027016-4cfa-42fa-95bc-18e52b6fe4a2",
      firstname: null,
      lastname: null,
      name: "restautrant1",
      mobile_no: "+11234567898",
      latitude: "24.882929",
      longitude: "67.069295",
      location: "",
    },
    user: {
      image_url:
        "http://api.wow.trangotechdevs.com:3011/1728033454593.octet-stream",
      slug: "c84d9188-c726-49d0-a292-7987e6152f77",
      firstname: "customer",
      lastname: "one",
      name: null,
      mobile_no: "+11234567988",
      latitude: "24.882929",
      longitude: "67.069295",
      location: "",
    },
    total_quantity: 1,
    total_amount: 54.75,
    biker_tip: 20,
    location: "House test 123 abc,abc",
    latitude: "24.878128909752306",
    longitude: "67.06904011392183",
    order_status: 8,
    order_status_title: "Completed",
    createdAt: "2024-10-16T12:38:42.000Z",
    pay_with: null,
  },
  {
    order_id: "# 63",
    slug: "0bcbc988-a863-4431-bfcc-2f788d8256ca",
    restaurant_slug: "9a027016-4cfa-42fa-95bc-18e52b6fe4a2",
    order_items: [
      {
        slug: "edf3eda9-a277-4a43-9a39-bd5fe08c0cd7",
        order_slug: "0bcbc988-a863-4431-bfcc-2f788d8256ca",
        item_slug: "6afd79cd-7435-499a-b7a5-40caecbe5111",
        quantity: 1,
        price: 17.95,
        special_request: "test",
        createdAt: "2024-10-16T11:40:31.000Z",
        restaurant_menu: {
          slug: "6afd79cd-7435-499a-b7a5-40caecbe5111",
          restaurant_slug: "9a027016-4cfa-42fa-95bc-18e52b6fe4a2",
          item_name: "Burger",
          item_description: "Freshly caught flounder sautéed to perfection",
          item_image:
            "http://api.wow.trangotechdevs.com:3011/1721896455732.jpeg",
          createdAt: "2024-07-25T08:34:15.000Z",
        },
        order_ingredient_items: [
          {
            slug: "78b0cedb-fb26-4103-a0b5-781a1572052a",
            title: "Lemon Sauce",
          },
          {
            slug: "151eb016-22c8-4d87-aa49-f056764bfd7b",
            title: "Grilled Vegetables",
          },
        ],
      },
    ],
    subtotal: 17.95,
    delivery_charges: 10,
    platform_fee: 5,
    gst: 1.8,
    payment_method: null,
    restaurant: {
      image_url: "https://api.wow.trangotechdevs.com/1726831869130.jpg",
      slug: "9a027016-4cfa-42fa-95bc-18e52b6fe4a2",
      firstname: null,
      lastname: null,
      name: "restautrant1",
      mobile_no: "+11234567898",
      latitude: "24.882929",
      longitude: "67.069295",
      location: "",
    },
    user: {
      image_url:
        "http://api.wow.trangotechdevs.com:3011/1728033454593.octet-stream",
      slug: "c84d9188-c726-49d0-a292-7987e6152f77",
      firstname: "customer",
      lastname: "one",
      name: null,
      mobile_no: "+11234567988",
      latitude: "24.882929",
      longitude: "67.069295",
      location: "",
    },
    total_quantity: 1,
    total_amount: 54.75,
    biker_tip: 20,
    location: "House test 123 abc,abc",
    latitude: "24.878128909752306",
    longitude: "67.06904011392183",
    order_status: 8,
    order_status_title: "Completed",
    createdAt: "2024-10-16T11:40:31.000Z",
    pay_with: null,
  },
  {
    order_id: "# 62",
    slug: "096a9911-dcfc-436b-b664-6c6df3a7ec46",
    restaurant_slug: "9a027016-4cfa-42fa-95bc-18e52b6fe4a2",
    order_items: [
      {
        slug: "bb052a6b-9f76-431f-a926-2b6491632c4d",
        order_slug: "096a9911-dcfc-436b-b664-6c6df3a7ec46",
        item_slug: "6afd79cd-7435-499a-b7a5-40caecbe5111",
        quantity: 1,
        price: 17.95,
        special_request: "test",
        createdAt: "2024-10-16T09:29:52.000Z",
        restaurant_menu: {
          slug: "6afd79cd-7435-499a-b7a5-40caecbe5111",
          restaurant_slug: "9a027016-4cfa-42fa-95bc-18e52b6fe4a2",
          item_name: "Burger",
          item_description: "Freshly caught flounder sautéed to perfection",
          item_image:
            "http://api.wow.trangotechdevs.com:3011/1721896455732.jpeg",
          createdAt: "2024-07-25T08:34:15.000Z",
        },
        order_ingredient_items: [
          {
            slug: "aad8909a-9a16-43f0-aed5-421c95a8d1c0",
            title: "No Sauce",
          },
          {
            slug: "ed55a97b-1db9-4f36-86d9-fbec3807bbd3",
            title: "Grilled Vegetables",
          },
        ],
      },
    ],
    subtotal: 17.95,
    delivery_charges: 10,
    platform_fee: 5,
    gst: 1.8,
    payment_method: null,
    restaurant: {
      image_url: "https://api.wow.trangotechdevs.com/1726831869130.jpg",
      slug: "9a027016-4cfa-42fa-95bc-18e52b6fe4a2",
      firstname: null,
      lastname: null,
      name: "restautrant1",
      mobile_no: "+11234567898",
      latitude: "24.882929",
      longitude: "67.069295",
      location: "",
    },
    user: {
      image_url:
        "http://api.wow.trangotechdevs.com:3011/1728033454593.octet-stream",
      slug: "c84d9188-c726-49d0-a292-7987e6152f77",
      firstname: "customer",
      lastname: "one",
      name: null,
      mobile_no: "+11234567988",
      latitude: "24.882929",
      longitude: "67.069295",
      location: "",
    },
    total_quantity: 1,
    total_amount: 54.75,
    biker_tip: 20,
    location: "House test 123 abc,abc",
    latitude: "24.878128909752306",
    longitude: "67.06904011392183",
    order_status: 8,
    order_status_title: "Completed",
    createdAt: "2024-10-16T09:29:52.000Z",
    pay_with: null,
  },
  {
    order_id: "# 61",
    slug: "845bfafc-0a0c-494d-b7ed-b586b10623a0",
    restaurant_slug: "9a027016-4cfa-42fa-95bc-18e52b6fe4a2",
    order_items: [
      {
        slug: "f121eb2c-655c-476e-bbc2-a4263ff59c62",
        order_slug: "845bfafc-0a0c-494d-b7ed-b586b10623a0",
        item_slug: "e626d2b5-569c-4520-b348-b076c1344f5e",
        quantity: 1,
        price: 50,
        special_request: "test",
        createdAt: "2024-10-16T08:47:04.000Z",
        restaurant_menu: {
          slug: "e626d2b5-569c-4520-b348-b076c1344f5e",
          restaurant_slug: "9a027016-4cfa-42fa-95bc-18e52b6fe4a2",
          item_name: "Chicken",
          item_description: "test test test",
          item_image:
            "http://api.wow.trangotechdevs.com:3011/1722328274941.jpg",
          createdAt: "2024-07-30T08:31:14.000Z",
        },
        order_ingredient_items: [
          {
            slug: "8ce6fadd-0e5f-4b00-9014-923b1319bf37",
            title: "Water",
          },
          {
            slug: "495dc8b4-5096-4b84-b399-e9e054ad3c02",
            title: "Garlic mayo",
          },
        ],
      },
      {
        slug: "fec03df1-cb25-4123-886f-14a096ec9e3d",
        order_slug: "845bfafc-0a0c-494d-b7ed-b586b10623a0",
        item_slug: "3f2df836-2284-42ba-b994-13d20bcdd595",
        quantity: 1,
        price: 50,
        special_request: "test",
        createdAt: "2024-10-16T08:47:04.000Z",
        restaurant_menu: {
          slug: "3f2df836-2284-42ba-b994-13d20bcdd595",
          restaurant_slug: "9a027016-4cfa-42fa-95bc-18e52b6fe4a2",
          item_name: "Pizza",
          item_description: "test",
          item_image:
            "http://api.wow.trangotechdevs.com:3011/1722265662964.jpg",
          createdAt: "2024-07-29T15:07:42.000Z",
        },
        order_ingredient_items: [
          {
            slug: "6b5ec47c-e1d6-432f-b434-62226185f9b0",
            title: "Fanta",
          },
          {
            slug: "95b0840b-fde7-42d2-8765-a2bce1cf1624",
            title: "Sprit",
          },
        ],
      },
    ],
    subtotal: 100,
    delivery_charges: 10,
    platform_fee: 5,
    gst: 5.5,
    payment_method: null,
    restaurant: {
      image_url: "https://api.wow.trangotechdevs.com/1726831869130.jpg",
      slug: "9a027016-4cfa-42fa-95bc-18e52b6fe4a2",
      firstname: null,
      lastname: null,
      name: "restautrant1",
      mobile_no: "+11234567898",
      latitude: "24.882929",
      longitude: "67.069295",
      location: "",
    },
    user: {
      image_url:
        "http://api.wow.trangotechdevs.com:3011/1728033454593.octet-stream",
      slug: "c84d9188-c726-49d0-a292-7987e6152f77",
      firstname: "customer",
      lastname: "one",
      name: null,
      mobile_no: "+11234567988",
      latitude: "24.882929",
      longitude: "67.069295",
      location: "",
    },
    total_quantity: 2,
    total_amount: 140.5,
    biker_tip: 20,
    location: "House test 123 abc,abc",
    latitude: "24.878128909752306",
    longitude: "67.06904011392183",
    order_status: 8,
    order_status_title: "Completed",
    createdAt: "2024-10-16T08:47:04.000Z",
    pay_with: null,
  },
  {
    order_id: "# 60",
    slug: "ee70203f-13d2-4945-9fa6-61dc6aea2859",
    restaurant_slug: "9a027016-4cfa-42fa-95bc-18e52b6fe4a2",
    order_items: [
      {
        slug: "1d5e82c0-9a5a-44c7-ae6b-cbf3c2297d2d",
        order_slug: "ee70203f-13d2-4945-9fa6-61dc6aea2859",
        item_slug: "e626d2b5-569c-4520-b348-b076c1344f5e",
        quantity: 2,
        price: 50,
        special_request: "test",
        createdAt: "2024-10-16T07:46:48.000Z",
        restaurant_menu: {
          slug: "e626d2b5-569c-4520-b348-b076c1344f5e",
          restaurant_slug: "9a027016-4cfa-42fa-95bc-18e52b6fe4a2",
          item_name: "Chicken",
          item_description: "test test test",
          item_image:
            "http://api.wow.trangotechdevs.com:3011/1722328274941.jpg",
          createdAt: "2024-07-30T08:31:14.000Z",
        },
        order_ingredient_items: [
          {
            slug: "51e93fa4-8e33-4dea-a2ca-50fbf8be524c",
            title: "Water",
          },
          {
            slug: "e272d23e-04ee-47dc-95d9-b03abe490be4",
            title: "Garlic mayo",
          },
        ],
      },
    ],
    subtotal: 100,
    delivery_charges: 10,
    platform_fee: 5,
    gst: 10,
    payment_method: null,
    restaurant: {
      image_url: "https://api.wow.trangotechdevs.com/1726831869130.jpg",
      slug: "9a027016-4cfa-42fa-95bc-18e52b6fe4a2",
      firstname: null,
      lastname: null,
      name: "restautrant1",
      mobile_no: "+11234567898",
      latitude: "24.882929",
      longitude: "67.069295",
      location: "",
    },
    user: {
      image_url:
        "http://api.wow.trangotechdevs.com:3011/1728033454593.octet-stream",
      slug: "c84d9188-c726-49d0-a292-7987e6152f77",
      firstname: "customer",
      lastname: "one",
      name: null,
      mobile_no: "+11234567988",
      latitude: "24.882929",
      longitude: "67.069295",
      location: "",
    },
    total_quantity: 2,
    total_amount: 145,
    biker_tip: 20,
    location: "House test 123 abc,abc",
    latitude: "24.878128909752306",
    longitude: "67.06904011392183",
    order_status: 8,
    order_status_title: "Completed",
    createdAt: "2024-10-16T07:46:48.000Z",
    pay_with: null,
  },
  {
    order_id: "# 59",
    slug: "86407234-b2f9-4ee8-b5ce-03a071323485",
    restaurant_slug: "9a027016-4cfa-42fa-95bc-18e52b6fe4a2",
    order_items: [
      {
        slug: "4af557f9-6524-4593-872c-de6bc8db7dea",
        order_slug: "86407234-b2f9-4ee8-b5ce-03a071323485",
        item_slug: "e626d2b5-569c-4520-b348-b076c1344f5e",
        quantity: 1,
        price: 50,
        special_request: "test",
        createdAt: "2024-10-16T07:14:21.000Z",
        restaurant_menu: {
          slug: "e626d2b5-569c-4520-b348-b076c1344f5e",
          restaurant_slug: "9a027016-4cfa-42fa-95bc-18e52b6fe4a2",
          item_name: "Chicken",
          item_description: "test test test",
          item_image:
            "http://api.wow.trangotechdevs.com:3011/1722328274941.jpg",
          createdAt: "2024-07-30T08:31:14.000Z",
        },
        order_ingredient_items: [
          {
            slug: "43167015-dab5-44d4-84a9-81ebd51c5ccb",
            title: "Water",
          },
          {
            slug: "aef19bb0-63af-4462-b98f-0187b6d58b0f",
            title: "Garlic mayo",
          },
        ],
      },
    ],
    subtotal: 50,
    delivery_charges: 10,
    platform_fee: 5,
    gst: 5,
    payment_method: null,
    restaurant: {
      image_url: "https://api.wow.trangotechdevs.com/1726831869130.jpg",
      slug: "9a027016-4cfa-42fa-95bc-18e52b6fe4a2",
      firstname: null,
      lastname: null,
      name: "restautrant1",
      mobile_no: "+11234567898",
      latitude: "24.882929",
      longitude: "67.069295",
      location: "",
    },
    user: {
      image_url:
        "http://api.wow.trangotechdevs.com:3011/1728033454593.octet-stream",
      slug: "c84d9188-c726-49d0-a292-7987e6152f77",
      firstname: "customer",
      lastname: "one",
      name: null,
      mobile_no: "+11234567988",
      latitude: "24.882929",
      longitude: "67.069295",
      location: "",
    },
    total_quantity: 1,
    total_amount: 90,
    biker_tip: 20,
    location: "House test 123 abc,abc",
    latitude: "24.878128909752306",
    longitude: "67.06904011392183",
    order_status: 8,
    order_status_title: "Completed",
    createdAt: "2024-10-16T07:14:21.000Z",
    pay_with: null,
  },
];

const OrderManagementScreen = ({ setPage }) => {
  const dispatch = useDispatch();

  // const [data, setData] = useState([]);
  const [indicationMessage, setIndicationMessage] = useState("loading...");
  const [rowData, setRowData] = useState([]);
  const [editedData, setEditedData] = useState("");

  const [loading, setLoading] = useState(false);
  const [detailModal, setDetailModal] = useState(false);
  // const [forBlock, setForBlock] = useState(true);
  const [editModal, setEditModal] = useState(false);

  const [currentPage, setCurrentPage] = useState(1); // todo
  const [pageSize, setPageSize] = useState(10); // todo
  const [totalRecords, setTotalRecords] = useState(0); // todo
  const [totalPages, setTotalPages] = useState(0); // todo
  const [dataFetched, setDataFetched] = useState(false); // todo

  const [searchQueryByUser, setSearchQueryByUser] = useState("");
  const [searchQueryByRestaurant, setSearchQueryByRestaurant] = useState("");

  // const debouncedSearchUser = useCallback(
  //   debounce((query) => {
  //     console.log("Search query:", query);
  //     // local search logic
  //     const filtered = dummy_data.filter((item) =>
  //       [
  //         item.user.firstname,
  //         item.user.lastname,
  //         item.user.username,
  //         item.user.email,
  //       ]
  //         .filter(Boolean) // Remove null/undefined fields
  //         .some((field) => field.toLowerCase().includes(query.toLowerCase()))
  //     );
  //     //
  //     setRowData(filtered);

  //     // Trigger your search logic or API here
  //   }, 500), // Adjust debounce delay as needed
  //   []
  // );

const { data, isSuccess, isLoading, isFetching, refetch } =
useGetAllOrdersQuery(
       {
        search_user: searchQueryByUser.length >= 3 ? searchQueryByUser : "",
        search_restaurant: searchQueryByRestaurant.length >= 3 ? searchQueryByRestaurant : "",
         limit: pageSize,
         page: currentPage,
       },
       { refetchOnMountOrArgChange: false }
     );


  // const debouncedSearchRestaurant = useCallback(
  //   debounce((query) => {
  //     const filtered = dummy_data.filter((item) =>
  //       [
  //         item.restaurant.firstname,
  //         item.restaurant.lastname,
  //         item.restaurant.name,
  //         item.restaurant.email,
  //       ]
  //         .filter(Boolean) // Remove null/undefined fields
  //         .some((field) => field.toLowerCase().includes(query.toLowerCase()))
  //     );

  //     setRowData(filtered);

  //     // Trigger your search logic or API here
  //   }, 500), // Adjust debounce delay as needed
  //   []
  // );

  const handleSearchByUser = (event) => {
    const query = event.target.value;
    setSearchQueryByUser(query);
  };

  const handleSearchByRestaurant = (event) => {
    const query = event.target.value;
    setSearchQueryByRestaurant(query);
  };

  useEffect(() => {
        if (data) {
          // console.log(data);
          setPageSize(data.links.per_page);
          setCurrentPage(data.links.current);
          setTotalRecords(data.links.total_records);
          setTotalPages(data.links.total);
          setRowData(data.data);
          setDataFetched(true);
        }
      }, [data]);

  const handleDetailModalOpen = () => {
    setDetailModal(true);
  };

  const handleDetailModalClose = () => {
    // console.log("close");
    setDetailModal(false);
    setEditedData("");
  };

  // const deleteData = (id) => {
  //   console.log(id);
  //   axios(`https://jsonplaceholder.typicode.com//posts/${id}`)
  //     .then((res) => {
  //       // console.log(res.data)
  //       // console.log(res.status)
  //       // setData(res.data);
  //       if (res.status === 200) {
  //         Toast("Deleted Successfully", "success");
  //         handleDetailModalClose();
  //       }
  //     })
  //     .catch((e) => {
  //       console.log(e);
  //       console.log(e.message);
  //       Toast("Something went wrong.", "error");
  //       // handleBlockModalClose();
  //       // setIndicationMessage("Something went wrong, refresh again");
  //     });
  // };

  // const handleEditModalOpen = () => {
  //   setEditModal(true);
  // };

  // const handleEditModalClose = () => {
  //   setEditModal(false);
  //   setEditedData("");
  // };

  // const handleAddUser = () => {
  //   setEditModal(true);
  // };

  // const handleBlockOrUnblock = (userSlug, actionType) => {
  //   console.log(userSlug);
  //   console.log(actionType);
  //   setLoading(true);
  //   setTimeout(() => {
  //     setLoading(false);
  //     handleDetailModalClose();
  //   }, 2000);
  // };

  // useEffect(() => {
  //   getData();
  //   return () => { };
  // }, []);

  // const getData = () => {
  //   // axios('https://jsonplaceholder.typicode.com/comments').then((res) => {
  //   axios("https://jsonplaceholder.typicode.com/users")
  //     .then((res) => {
  //       // console.log(res.data)
  //       // console.log(res.status)
  //       setIndicationMessage("");

  //       setData(res.data);

  //       setData((prv) => [...prv, res.data]); // todo:

  //     })
  //     .catch((e) => {
  //       console.log(e);
  //       setIndicationMessage("Something went wrong, refresh again");
  //     });
  // };

  function indication() {
    // return something here
    // return 'Table is empty';
    return indicationMessage;
  }

  const columns = [
    {
      dataField: "order_id",
      text: "Order #",
      // sort: true,
      headerStyle: {
        width: "50px",
      },
    },
    {
      dataField: "username",
      text: "User",
      sort: true,
      headerStyle: {
        width: "80px",
      },
      formatter: (cellContent, row) => {
        // console.log(row.user);
        const userName = row.user.firstname + row.user.lastname;
        return (
          <NameImageComponent name={userName} image={row.user.image_url} />
        );
      },
    },
    {
      dataField: "restaurant_name",
      text: "Restaurant",
      sort: true,
      headerStyle: {
        width: "80px",
      },
      formatter: (cellContent, row) => {
        return (
          <NameImageComponent
            name={row.restaurant.name}
            image={row.restaurant.image_url}
          />
        );
      },
    },
    {
      dataField: "order_items",
      text: "Item (s)",
      // sort: true,
      headerAlign: "center",
      align: "center",
      headerStyle: {
        width: "30px",
      },
      formatter: (cellContent, row) => {
        return <span className="lc-1"> {row.order_items.length}</span>;
      },
    },
    {
      dataField: "subtotal",
      text: "Sub Total",
      // sort: true,
      headerAlign: "center",
      align: "center",
      headerStyle: {
        width: "30px",
      },
    },
    {
      dataField: "delivery_charges",
      text: "DC",
      // sort: true,
      headerAlign: "center",
      align: "center",
      headerStyle: {
        width: "30px",
      },
    },
    {
      dataField: "platform_fee",
      text: "PF",
      // sort: true,
      headerAlign: "center",
      align: "center",
      headerStyle: {
        width: "30px",
      },
    },
    {
      dataField: "gst",
      text: "GST",
      // sort: true,
      headerAlign: "center",
      align: "center",
      headerStyle: {
        width: "30px",
      },
    },
    {
      dataField: "total_amount",
      text: "Order Amount",
      // sort: true,
      headerAlign: "center",
      align: "center",
      headerStyle: {
        width: "30px",
      },
    },
    {
      dataField: "createdAt",
      text: "Date",
      sort: true,
      headerAlign: "center",
      align: "center",
      headerStyle: {
        width: "50px",
      },
      formatter: (cellContent, row) => {
        const agoFormat = moment(row.createdAt).fromNow();
        // return <span className=" fs-7"> {agoFormat}</span>;
        return (
          <OverlayTrigger
            placement="right"
            delay={{ show: 250, hide: 400 }}
            overlay={renderTooltip(row.createdAt)}
          >
            <span className=" fs-7 cursor-pointer"> {agoFormat}</span>
          </OverlayTrigger>
        );
      },
    },
    {
      dataField: "order_status_title",
      text: "Status",
      sort: true,
      headerAlign: "center",
      align: "center",
      headerStyle: {
        width: "50px",
      },
      formatter: (cellContent, row) => {
        if (
          row.order_status_title === OrderStatus.ACCEPTED ||
          row.order_status_title === OrderStatus.COMPLETED ||
          row.order_status_title === OrderStatus.ORDER_DELIVERED
        ) {
          return (
            <Badge pill bg="" className="p-2 bg-success">
              {row.order_status_title}
            </Badge>
          );
        } else if (
          row.order_status_title === OrderStatus.ON_THE_WAY ||
          row.order_status_title === OrderStatus.PICKED_ORDER ||
          row.order_status_title === OrderStatus.REACHED_AT_SHOP
        ) {
          return (
            <Badge pill bg="" className="bg-warning p-2">
              {row.order_status_title}
            </Badge>
          );
        } else if (row.order_status_title === OrderStatus.UNACCEPTED_ORDER) {
          return (
            <Badge pill bg="" className="bg-danger p-2">
              {row.order_status_title}
            </Badge>
          );
        }
      },
    },
    {
      dataField: "actions",
      text: "Actions",
      headerAlign: "center",
      align: "center",
      headerStyle: {
        width: "100px",
      },
      formatter: (cellContent, row) => {
        // console.log(row)
        if (row.slug) {
          return (
            <div className="d-flex justify-content-center align-items-center ">
              
              <div
                className="d-flex justify-content-center align-items-center bg-primary-hover rounded-3 p-2 mx-2 "
                role="button"
                onClick={() => {
                  setEditedData(row);
                  handleDetailModalOpen();
                }}
              >
                <span className={`d-flex align-items-center pe-1`}>
                  <IoMdEye />
                </span>
                <span className="d-none d-xl-block">View</span>
              </div>
            </div>
          );
        }
      },
    },
  ];

  return (
    <div className={styles.MainContainer}>
      {detailModal ? (
        <Suspense fallback={<Spinner animation="grow" size="sm" />}>

        <OrderDetailModal
          handleModalClose={handleDetailModalClose}
          rowData={editedData}
          // onAction={handleBlockOrUnblock}
          loading={loading}
          />
          </Suspense>
      ) : (
        ""
      )}

      {
        //   editModal ? (
        //   <EditUserModal
        //     handleEditModalClose={handleEditModalClose}
        //     data={editedData}
        //   />
        // ) : (
        //   ""
        // )
      }

      <div className={styles.headerContainer}>
        <Row className="w-100 align-items-center">
          <Col md={6}>
            <div>
              <SubTitle text={"Orders Management"} classes="text-black" />
            </div>
          </Col>

          <Col md={6}>
            <div className="d-flex flex-row gap-2">
              <Input
                placeholder="search by User"
                value={searchQueryByUser}
                onChange={handleSearchByUser}
              />
              <Input
                placeholder="search by Restaurant"
                value={searchQueryByRestaurant}
                onChange={handleSearchByRestaurant}
              />
            </div>
          </Col>
        </Row>

        {
          // <Row>
          //   <Col md={12}>
          //     <Button
          //       classes={`${styles.cardBtn} `}
          //       text="Add User"
          //       onClick={handleAddUser}
          //     ></Button>
          //   </Col>
          // </Row>
        }
      </div>

      {!dataFetched || !isSuccess ? (
          <CustomLoader />
        ) : (
        <div className={styles.mainTable}>
          {<Table data={rowData} columns={columns} indication={indication}
          
          totalRecords={totalRecords} // todo
          totalPages={totalPages} // todo
          pageSize={pageSize} // todo
          currentPage={currentPage} // todo
          setCurrentPage={setCurrentPage} // todo
          setPageSize={setPageSize} // todo
          refetch={refetch} // todo
          />}
        </div>
      )}
    </div>
  );
};

const renderTooltip = (date) => (
  <Tooltip id="button-tooltip">
    {moment(date).format("YYYY-MM-DD HH:mm:ss")}
  </Tooltip>
);

export default OrderManagementScreen;
