import React from "react";
import { Navigate } from "react-router-dom";
import { WEB_ROUTES } from "../../constants";
import { getToken } from "../../Data/services/localStorageService";
import { baseRoute } from "../../Helper";

function ProtectedRoute({ children }) {
  const token = getToken();
  return token ? (
    children
  ) : (
    <Navigate to={baseRoute(WEB_ROUTES.ACCOUNT)} replace />
  );
}

export default ProtectedRoute;
