import React, { lazy, Suspense, useCallback, useEffect, useState } from "react";
import "react-bootstrap-table-next/dist/react-bootstrap-table2.min.css";
import "react-bootstrap-table2-toolkit/dist/react-bootstrap-table2-toolkit.min.css";
import { Input, SubTitle } from "../../Components/CustomComponents/index.js";
import Table from "../../Components/Table/Table.js";
import styles from "./styles/user_management.module.scss";
// import { ToastContainer, toast } from "react-toastify";
import { IoMdEye } from "react-icons/io";
//
import { MdDeleteForever, MdDone } from "react-icons/md";
import { useDispatch } from "react-redux"; // for getting and setting data into local redux
// import CustomerBlockModal from "../../Components/Modals/CustomerBlockModal";
import { debounce } from "lodash";
import { Col, Row, Spinner } from "react-bootstrap";
import CustomSpinner from "../../Components/CustomComponents/CustomSpinner.js";
import { useApproveRiderMutation, useGetAllRiderQuery, useGetAllRidersQuery, userGetRiderDetailQuery, userGetRiderDetailsQuery, useUnApproveRiderMutation } from "../../Data/services/ridersApi.js";
import CustomLoader from "../../Components/CustomComponents/CustomLoader.js";
import Toast, { SuccessToast } from "../../Hooks/useToast.js";
import { useDeleteUserMutation } from "../../Data/services/userApi.js";
import DeleteModal from "../../Components/Modals/DeleteModal.js";

const RiderDetailModal = lazy(() =>
  import("../../Components/Modals/RiderDetailModal.js")
);

const RiderManagementScreen = ({ setPage }) => {
  const dispatch = useDispatch();

  // const [data, setData] = useState([]);
  const [indicationMessage, setIndicationMessage] = useState("loading...");
  const [rowData, setRowData] = useState([]);
  const [editedData, setEditedData] = useState("");

  const [loading, setLoading] = useState(false);
  const [detailModal, setDetailModal] = useState(false);
  const [forBlock, setForBlock] = useState(true);
  const [editModal, setEditModal] = useState(false);
     const [deleteModal, setDeleteModal] = useState(false);
  

    const [currentPage, setCurrentPage] = useState(1); // todo
    const [pageSize, setPageSize] = useState(10); // todo
    const [totalRecords, setTotalRecords] = useState(0); // todo
    const [totalPages, setTotalPages] = useState(0); // todo
    const [dataFetched, setDataFetched] = useState(false); // todo
  
  const [searchQuery, setSearchQuery] = useState("");

  const { data, isSuccess, isLoading, isFetching, refetch } =
  useGetAllRiderQuery(
       {
         search: searchQuery.length >= 3 ? searchQuery : "",
         limit: pageSize,
         page: currentPage,
       },
       { refetchOnMountOrArgChange: true }
     );

  const handleSearchChange = (event) => {
    const query = event.target.value;
    setSearchQuery(query);
  };

  useEffect(() => {
      if (data) {
        // console.log(data);
        setPageSize(data.links.per_page);
        setCurrentPage(data.links.current);
        setTotalRecords(data.links.total_records);
        setTotalPages(data.links.total);
        setRowData(data.data);
        setDataFetched(true);
      }
    }, [data]);

  const handleDetailModalOpen = () => {
    setDetailModal(true);
  };

  const handleDetailModalClose = () => {
    setDetailModal(false);
    setEditedData("");
  };

   const [approveRider, { isLoading: isApproving }] = useApproveRiderMutation();
    const [unApproveRider, { isLoading: isUnApproving }] = useUnApproveRiderMutation();

  const handleApprovedOrRejected = async (userSlug, isApprove) => {
    // console.log(userSlug);
    // console.log(isApprove);

    setLoading(true);
    
    try {
      let response;
      if (isApprove) {
        // console.log('approve')
        response = await approveRider(userSlug);
      } else {
        // console.log('rejected')
        response = await unApproveRider(userSlug);
      }

      if (response.data.code === 200) {
        await refetch();
        setLoading(false);
        handleDetailModalClose();
        SuccessToast(response.data?.message);
      }
    } catch (error) {
      // console.log(error);
      Toast(error.message, "error");
    }
  };



  const handleDeleteModalOpen = () => {
    setDeleteModal(true);
  };

  const handleDeleteModalClose = () => {
    setDeleteModal(false);
    setEditedData("");
  };

  const[deleteUser, {isLoading: isDeleting}] = useDeleteUserMutation();

  const deleteData = async (slug) => {


    try {
      const { data } = await deleteUser(slug);

      // console.log(data);

      if (data.code === 200) {
        await refetch();
        handleDeleteModalClose();
        SuccessToast(data.message);
      }
    } catch (error) {
      // console.log(error);
      Toast(error.message, "error");
    }
    
  };

  function indication() {
    // return something here
    // return 'Table is empty';
    return indicationMessage;
  }

  

  // MARK: TABLE FIELDS
  const columns = [
    {
      dataField: "name",
      text: "Name",
      sort: true,
      headerStyle: {
        width: "150px",
      },
      formatter: (cellContent, row) => {
        // return row.firstname + row.lastname;
        return (
          <div className="d-flex flex-row justify-content-start align-items-center gap-2">
            <img
              alt="user"
              src={row.image_url}
              loading="lazy"
              width={48}
              height={48}
              className="rounded-circle object-fit-cover"
            />
            <div className="lc-1"> {`${row.firstname} ${row.lastname}`} </div>
          </div>
        );
      },
    },
    {
      dataField: "email",
      text: "Email",
      // sort: true,
      headerStyle: {
        width: "150px",
      },
      formatter: (cellContent, row) => {
        return <span className="lc-1"> {row.email}</span>;
      },
    },
    {
      dataField: "mobile_no",
      text: "Phone",
      // sort: true,
      headerAlign: "center",
      align: "center",
      headerStyle: {
        width: "100px",
      },
      formatter: (cellContent, row) => {
        return <span className="lc-1"> {row.mobile_no}</span>;
      },
    },
    {
      dataField: "is_blocked",
      text: "Status",
      sort: true,
      headerAlign: "center",
      align: "center",
      headerStyle: {
        width: "150px",
      },
      formatter: (cellContent, row) => {
        if (row.is_blocked)
          return <div className={`${styles.block}`}>Block</div>;
        return (
          <div className={`${styles.active}`}>
            <span className="d-none d-xl-block">Active </span>
            <span className="d-block d-xl-none">
              <MdDone />
            </span>
          </div>
        );
      },
    },
    {
      dataField: "actions",
      text: "Actions",
      headerAlign: "center",
      align: "center",
      headerStyle: {
        width: "200px",
      },
      formatter: (cellContent, row) => {
        // console.log(row)
        if (row.slug) {
          return (
            <div className="d-flex justify-content-center align-items-center ">
              
              <div
                className="d-flex justify-content-center align-items-center bg-primary-hover rounded-3 p-2 mx-2 "
                role="button"
                onClick={() => {
                  setEditedData(row);
                  handleDetailModalOpen();
                }}
              >
                <span className={`d-flex align-items-center pe-1`}>
                  <IoMdEye />
                </span>
                <span className="d-none d-xl-block">View</span>
              </div>
              <div
                                            className="d-flex justify-content-center align-items-center bg-danger text-white rounded-3 p-2 mx-2 "
                                            role="button"
                                            onClick={() => {
                                              setEditedData(row);
                                              handleDeleteModalOpen();
                                            }}
                                          >
                                            <span className={`d-flex align-items-center pe-1`}>
                                              <MdDeleteForever />
                                            </span>
                                            <span>Delete</span>
                                          </div>
            </div>
          );
        }
      },
    },
  ];

  return (
    <div className={styles.MainContainer}>
      {detailModal ? (
        <Suspense fallback={<Spinner animation="grow" size="sm" />}>
          <RiderDetailModal
            handleModalClose={handleDetailModalClose}
            rowData={editedData}
            onAction={handleApprovedOrRejected}
            loading={loading}
          />
        </Suspense>
      ) : (
        ""
      )}

{deleteModal ? (
        <Suspense fallback={<Spinner animation="grow" size="sm" />}>
          <DeleteModal
            handleDeleteModalClose={handleDeleteModalClose}
            data={editedData}
            isDeleting={isDeleting}
            onDelete={deleteData}
          />
        </Suspense>
      ) : (
        ""
      )}

      <div className={styles.headerContainer}>
        <Row className="w-100 align-items-center">
          <Col md={6}>
            <div>
              <SubTitle text={"Riders Management"} classes="text-black" />
            </div>
          </Col>

          <Col md={6}>
            <Input
              placeholder="search by name"
              value={searchQuery}
              onChange={handleSearchChange}
            />
          </Col>
        </Row>

        {
          // <Row>
          //   <Col md={12}>
          //     <Button
          //       classes={`${styles.cardBtn} `}
          //       text="Add User"
          //       onClick={handleAddUser}
          //     ></Button>
          //   </Col>
          // </Row>
        }
      </div>
      {!dataFetched || !isSuccess ? (
          <CustomLoader />
        ) : (
        <div className={styles.mainTable}>
          {<Table data={rowData} columns={columns} indication={indication}
          
          totalRecords={totalRecords} // todo
          totalPages={totalPages} // todo
          pageSize={pageSize} // todo
          currentPage={currentPage} // todo
          setCurrentPage={setCurrentPage} // todo
          setPageSize={setPageSize} // todo
          refetch={refetch} // todo
          />}
        </div>
      )}
    </div>
  );
};

export default RiderManagementScreen;
