import React, { useState } from "react";
import { Link, useNavigate } from "react-router-dom";

/**Import Assets */
import styles from "./styles/ProtectedLayout.module.scss";
// import cssStyles from './styles/notification.module.css';

/**Import Custom Component */
import { WEB_ROUTES } from "../../constants";
import { removeToken } from "../../Data/services/localStorageService";
import { baseRoute } from "../../Helper";
import Toast from "../../Hooks/useToast";
import LogoutModal from "../Modals/LogoutModal";
import Navbar from "./Navbar";

// Icons
import { uniqueId } from "lodash";
import { AiOutlineFileText, AiOutlineTransaction } from "react-icons/ai";
import { FaUsers } from "react-icons/fa6";
import { IoIosSettings } from "react-icons/io";
import { IoBusinessSharp, IoNotifications } from "react-icons/io5";
import { LuBike } from "react-icons/lu";
import {
  MdBugReport,
  MdDashboard,
  MdReport,
  MdRestaurantMenu,
} from "react-icons/md";
import { PiBowlFood } from "react-icons/pi";
import { TbLogout2 } from "react-icons/tb";
import { useGetDashboardDetailsQuery } from "../../Data/services/dashboardApi";

export default function ProtectedLayout({
  isSearchBar,
  isSettingButton,
  selectedRoute,
  children,
}) {
  const [collapsed, setCollapsed] = useState(window.innerWidth > 991);

  // inject data
  const { data } = useGetDashboardDetailsQuery({
    refetchOnMountOrArgChange: true,
  });

  const [logoutModal, setLogoutModal] = useState(false);

  const navigate = useNavigate();
  // todo: menu
  const items = [
    {
      label: "Dashboard",
      icon: <MdDashboard />,
    },
    {
      label: "Customer Management",
      icon: <FaUsers />,
    },
    {
      label: "Restaurant Management",
      icon: <MdRestaurantMenu />,
    },
    {
      label: "Riders Management",
      icon: <LuBike />,
    },
    {
      label: "Orders Management",
      icon: <AiOutlineFileText />,
    },
    {
      label: "Transaction History",
      icon: <AiOutlineTransaction />,
    },
    {
      label: "Reports",
      icon: <MdReport />,
    },
    {
      label: "Business Type Management",
      icon: <IoBusinessSharp />,
    },
    {
      label: "Cuisine Management",
      icon: <PiBowlFood />,
    },
    {
      label: "Report Type Management",
      icon: <MdBugReport />,
    },
    {
      label: "Send Notification",
      icon: <IoNotifications />,
    },
    {
      label: "Settings",
      icon: <IoIosSettings />,
    },
  ];

  const handleToggle = () => {
    setCollapsed(!collapsed);
  };

  const handleMenuClose = () => {
    setCollapsed(false);
  };

  const handleViewProfile = () => {
    navigate(baseRoute(WEB_ROUTES.PROFILE));
  };

  const handleLogoutModalOpen = () => {
    setLogoutModal(true);
  };

  const handleLogoutModalClose = () => {
    setLogoutModal(false);
  };

  const handleLogout = () => {
    try {
      removeToken();
      navigate("/account");
      window.location.reload();
      Toast("Logged Out Successfully", "success");
    } catch {
      Toast("An error occurred during log out", "error");
    }
  };

  return (
    <div className={styles.ProtectedLayout}>
      <div
        className={collapsed ? styles.background : ""}
        onClick={handleMenuClose}
      ></div>
      <div
        className={`${styles.menu} ${
          collapsed ? `${styles.collapsed} visible` : styles.notCollapsed
        }`}
        id="sider"
      >
        <div
          className={`${styles.logo} ${!collapsed ? styles.notCollapsed : ""}`}
          to={`${process.env.REACT_APP_DOMAIN_DIR}/dashboard`}
        >
          <img
            src={"/images/logo_name.png"} //todo:
            alt="Logo"
            className={
              collapsed ? styles.imageCollapsed : styles.imageNotCollapsed
            }
            width={"100%"}
            // height={"80%"}
          />
        </div>

        {/* <div className={`${styles.drawer} ${collapsed ? styles.collapsed : ''}`}>

                    <span onClick={handleToggle}>
                        <BiLogOut />
                    </span>
                </div> */}

        {items.map(({ icon, label }, index) => {
          return (
            <div key={uniqueId()}>
              {label === "Send Notification" ||
              label === "Transaction History" ||
              label === "Business Type Management" ? (
                <hr key={uniqueId()}></hr>
              ) : null}
              <Link
                to={`${process.env.REACT_APP_DOMAIN_DIR}/${label
                  .toLowerCase()
                  .replace(/[^A-Z0-9]/gi, "-")}`}
                className={`${styles.item} ${
                  index === selectedRoute ? styles.active : ""
                }`}
                key={uniqueId()}
              >
                <span style={{ fontSize: "20px", display: "flex" }}>
                  {icon}
                </span>
                {collapsed ? <p>{label}</p> : ""}
              </Link>
            </div>
          );
        })}
        <Link
          className={styles.item}
          onClick={handleLogoutModalOpen}
          // onClick={() => submit("Confirm Logout", "Are you sure you want to logout?", () => handleLogout())}
        >
          {/* <img src={logout} alt="Icon1" width={20} height={20} /> */}
          <span style={{ fontSize: "20px", display: "flex" }}>
            <TbLogout2 />
          </span>
          {collapsed ? <p>Logout</p> : ""}
        </Link>
      </div>
      <div className={styles.routeContent}>
        {logoutModal ? (
          <LogoutModal
            handleLogoutModalClose={handleLogoutModalClose}
            data={""}
            onLogout={handleLogout}
          />
        ) : null}
        <Navbar
          isSearchBar={isSearchBar}
          isSettingButton={isSettingButton}
          handleToggle={handleToggle}
        />
        <div className={styles.content}>{children}</div>
      </div>
    </div>
  );
}
