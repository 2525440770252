import React, { useMemo } from "react";
import {
  Navigate,
  Route,
  BrowserRouter as Router,
  Routes,
} from "react-router-dom";

// global scss for globally styles and fonts
import "./_fonts.scss";
import "./_global.scss";

import PublicRoute from "./Components/Routes/PublicRoute.js";
import { WEB_ROUTES } from "./constants.js";
import { baseRoute } from "./Helper";

import ProtectedLayout from "./Components/Layouts/ProtectedLayout.js";
import Account from "./Pages/Account";
import Dashboard from "./Pages/Dashboard";
// import Tournaments from "./Pages/Tournaments";
// import Messages from "./Pages/Messages";
import Setting from "./Pages/Setting";
// import Profile from "./Pages/Profile";
// import PaymentMethod from "./Pages/PaymentMethod";
import { uniqueId } from "lodash";
import ProtectedRoute from "./Components/Routes/ProtectedRoute.js";
import BusinessTypeManagementScreen from "./Pages/BusinessTypeManagement/BusinessTypeManagementScreen.js";
import CuisineManagementScreen from "./Pages/CuisineManagement/CuisineManagementScreen.js";
import UserManagement from "./Pages/CustomerManagement/index.js";
import OrderManagementScreen from "./Pages/OrderManagement/OrderManagementScreen.js";
import ReportsScreen from "./Pages/Reports/ReportsScreen.js";
import ReportTypeManagementScreen from "./Pages/ReportTypeManagement/ReportTypeManagementScreen.js";
import RestaurantManagementScreen from "./Pages/RestaurantManagement/RestaurantManagementScreen.js";
import RiderManagementScreen from "./Pages/RidersManagement/RidersManagementScreen.js";
import SendNotificationScreen from "./Pages/SendNotification/SendNotificationScreen.js";
import TransactionHistoryScreen from "./Pages/TransactionHistory/TransactionHistoryScreen.js";
// import TournamentManagementScreen from "./Pages/TournamentManagement/TournamentManagementScreen.js";
// import TournamentManagement from './Pages/TournamentManagement';
function App() {
  // todo: menu
  const ROUTES = useMemo(() => {
    return [
      <Route
        key={uniqueId}
        exact
        path={baseRoute(WEB_ROUTES.ACCOUNT)}
        element={
          <PublicRoute>
            <Account />
          </PublicRoute>
        }
      />,
      <Route
        key={uniqueId}
        exact
        path={baseRoute(WEB_ROUTES.DASHBOARD)}
        element={
          <ProtectedRoute>
            <ProtectedLayout isSearchBar={false} selectedRoute={0}>
              <Dashboard />
            </ProtectedLayout>
          </ProtectedRoute>
        }
      />,
      <Route
        key={uniqueId}
        exact
        path={baseRoute(WEB_ROUTES.CUSTOMER_MANAGEMENT)}
        element={
          <ProtectedRoute>
            <ProtectedLayout isSearchBar={false} selectedRoute={1}>
              <UserManagement />
            </ProtectedLayout>
          </ProtectedRoute>
        }
      />,
      <Route
        key={uniqueId}
        exact
        path={baseRoute(WEB_ROUTES.RESTAURANT_MANAGEMENT)}
        element={
          <ProtectedRoute>
            <ProtectedLayout isSearchBar={false} selectedRoute={2}>
              <RestaurantManagementScreen />
            </ProtectedLayout>
          </ProtectedRoute>
        }
      />,
      <Route
        key={uniqueId}
        exact
        path={baseRoute(WEB_ROUTES.RIDERS_MANAGEMENT)}
        element={
          <ProtectedRoute>
            <ProtectedLayout isSearchBar={false} selectedRoute={3}>
              <RiderManagementScreen />
            </ProtectedLayout>
          </ProtectedRoute>
        }
      />,
      <Route
        key={uniqueId}
        exact
        path={baseRoute(WEB_ROUTES.ORDERS_MANAGEMENT)}
        element={
          <ProtectedRoute>
            <ProtectedLayout isSearchBar={false} selectedRoute={4}>
              <OrderManagementScreen />
            </ProtectedLayout>
          </ProtectedRoute>
        }
      />,
      <Route
        key={uniqueId}
        exact
        path={baseRoute(WEB_ROUTES.TRANSACTION_HISTORY)}
        element={
          <ProtectedRoute>
            <ProtectedLayout isSearchBar={false} selectedRoute={5}>
              <TransactionHistoryScreen />
            </ProtectedLayout>
          </ProtectedRoute>
        }
      />,
      <Route
        key={uniqueId}
        exact
        path={baseRoute(WEB_ROUTES.REPORTS)}
        element={
          <ProtectedRoute>
            <ProtectedLayout isSearchBar={false} selectedRoute={6}>
              <ReportsScreen />
            </ProtectedLayout>
          </ProtectedRoute>
        }
      />,
      <Route
        key={uniqueId}
        exact
        path={baseRoute(WEB_ROUTES.BUSINESS_TYPE_MANAGEMENT)}
        element={
          <ProtectedRoute>
            <ProtectedLayout isSearchBar={false} selectedRoute={7}>
              <BusinessTypeManagementScreen />
            </ProtectedLayout>
          </ProtectedRoute>
        }
      />,
      <Route
        key={uniqueId}
        exact
        path={baseRoute(WEB_ROUTES.CUISINE_MANAGEMENT)}
        element={
          <ProtectedRoute>
            <ProtectedLayout isSearchBar={false} selectedRoute={8}>
              <CuisineManagementScreen />
            </ProtectedLayout>
          </ProtectedRoute>
        }
      />,
      <Route
        key={uniqueId}
        exact
        path={baseRoute(WEB_ROUTES.REPORT_TYPE_MANAGEMENT)}
        element={
          <ProtectedRoute>
            <ProtectedLayout isSearchBar={false} selectedRoute={9}>
              <ReportTypeManagementScreen />
            </ProtectedLayout>
          </ProtectedRoute>
        }
      />,

      // divider
      <Route
        key={uniqueId}
        exact
        path={baseRoute(WEB_ROUTES.SEND_NOTIFICATION)}
        element={
          <ProtectedRoute>
            <ProtectedLayout isSearchBar={false} selectedRoute={10}>
              <SendNotificationScreen />
            </ProtectedLayout>
          </ProtectedRoute>
        }
      />,
      <Route
        key={uniqueId}
        exact
        path={baseRoute(WEB_ROUTES.SETTING)}
        element={
          <ProtectedRoute>
            <ProtectedLayout isSearchBar={false} selectedRoute={11}>
              <Setting />
            </ProtectedLayout>
          </ProtectedRoute>
        }
      />,
      // <Route key={uniqueId} exact path={baseRoute(WEB_ROUTES.PROFILE)} element={
      //   <ProtectedRoute>
      //     <ProtectedLayout isSearchBar={false} selectedRoute={2}>
      //       <Profile />
      //     </ProtectedLayout>
      //   </ProtectedRoute>
      // } />,
    ];
  }, []);

  return (
    <Router>
      <Routes>
        {ROUTES.map((item) => item)}
        <Route
          path={baseRoute("*")}
          element={<Navigate to={baseRoute(WEB_ROUTES.ACCOUNT)} />}
        />
      </Routes>
    </Router>
  );
}

export default App;
