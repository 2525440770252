// const BASE_URL = "http://localhost:3001";
// const BASE_URL = "http://api.wow.trangotechdevs.com:3011/"; // staging
const BASE_URL = "https://api.wow.trangotechdevs.com/"; // staging
// const BASE_URL = "https://api.wowspent.com/"; // production

const API_URL = BASE_URL + "api/admin/";

const INITIAL_TOKEN = "e6e2b3f3-855f-454d-b947-8b0504933a1b";

export { API_URL, BASE_URL, INITIAL_TOKEN };
