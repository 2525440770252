import React, { lazy, useCallback, useEffect, useState } from "react";
import "react-bootstrap-table-next/dist/react-bootstrap-table2.min.css";
import "react-bootstrap-table2-toolkit/dist/react-bootstrap-table2-toolkit.min.css";
import {
  Button,
  Input,
  SubTitle,
} from "../../Components/CustomComponents/index.js";
import Table from "../../Components/Table/Table.js";
import styles from "./styles/user_management.module.scss";
// import { ToastContainer, toast } from "react-toastify";

//
import { useDispatch } from "react-redux"; // for getting and setting data into local redux
// import CustomerBlockModal from "../../Components/Modals/CustomerBlockModal";
import { debounce } from "lodash";
import moment from "moment";
import { Badge, Col, OverlayTrigger, Row, Tooltip } from "react-bootstrap";
import { MdOutlineDone } from "react-icons/md";
import { RxCross1 } from "react-icons/rx";
import { useGetAllTxnHistoryQuery } from "../../Data/services/transactionApi.js";
import CustomLoader from "../../Components/CustomComponents/CustomLoader.js";

const CustomerBlockModal = lazy(() =>
  import("../../Components/Modals/CustomerBlockModal.js")
);
// MARK: DUMMY DATA
// const dummy_data = [
//   {
//     slug: "bf1411ae-2857-4945-98c7-fe719657060c",
//     user_slug: "9a027016-4cfa-42fa-95bc-18e52b6fe4a2",
//     order_slug: null,
//     gateway_transaction_id: "TRF_5axdj8y0s9pcjcrn",
//     transaction_type: "debit",
//     previous_amount: 2840.95,
//     new_amount: 2835.95,
//     transaction_amount: 5,
//     is_tip: false,
//     status: "pending",
//     description: "Withdraw of amount $5",
//     created_at: "2024-12-03T13:34:13.000Z",
//     user: {
//       id: 38,
//       slug: "9a027016-4cfa-42fa-95bc-18e52b6fe4a2",
//       user_type: "RESTAURANT",
//       firstname: null,
//       lastname: null,
//       email: "restautrant1@yopmail.com",
//       mobile_no: "+11234567898",
//     },
//   },
//   {
//     slug: "7744e11f-4d30-4f53-8d0a-f090ea73ec63",
//     user_slug: "9a027016-4cfa-42fa-95bc-18e52b6fe4a2",
//     order_slug: null,
//     gateway_transaction_id: "TRF_wwn0ube4upppdm28",
//     transaction_type: "debit",
//     previous_amount: 2845.95,
//     new_amount: 2840.95,
//     transaction_amount: 5,
//     is_tip: false,
//     status: "pending",
//     description: "Withdraw of amount $5",
//     created_at: "2024-11-29T07:17:05.000Z",
//     user: {
//       id: 38,
//       slug: "9a027016-4cfa-42fa-95bc-18e52b6fe4a2",
//       user_type: "RESTAURANT",
//       firstname: null,
//       lastname: null,
//       email: "restautrant1@yopmail.com",
//       mobile_no: "+11234567898",
//     },
//   },
//   {
//     slug: "d33804db-f8a1-408e-97a5-df0dd5f58c91",
//     user_slug: "91a56791-552c-4028-a81f-98895f504a5e",
//     order_slug: null,
//     gateway_transaction_id: "tr_1QQ8SbI5pBhNo0CO12cM2H7I",
//     transaction_type: "debit",
//     previous_amount: 75,
//     new_amount: 60,
//     transaction_amount: 15,
//     is_tip: false,
//     status: "succeed",
//     description: "Withdraw of amount $15",
//     created_at: "2024-11-28T14:12:50.000Z",
//     user: {
//       id: 43,
//       slug: "91a56791-552c-4028-a81f-98895f504a5e",
//       user_type: "RESTAURANT",
//       firstname: null,
//       lastname: null,
//       email: "sarfoelijah1@gmail.com",
//       mobile_no: "+233532871143",
//     },
//   },
//   {
//     slug: "bf7f16d7-9d0f-47c3-87a4-301a6fc89223",
//     user_slug: "91a56791-552c-4028-a81f-98895f504a5e",
//     order_slug: null,
//     gateway_transaction_id: "tr_1QQ8STI5pBhNo0COgaZBhFdL",
//     transaction_type: "debit",
//     previous_amount: 80,
//     new_amount: 75,
//     transaction_amount: 5,
//     is_tip: false,
//     status: "succeed",
//     description: "Withdraw of amount $5",
//     created_at: "2024-11-28T14:12:42.000Z",
//     user: {
//       id: 43,
//       slug: "91a56791-552c-4028-a81f-98895f504a5e",
//       user_type: "RESTAURANT",
//       firstname: null,
//       lastname: null,
//       email: "sarfoelijah1@gmail.com",
//       mobile_no: "+233532871143",
//     },
//   },
//   {
//     slug: "826ea1d5-5167-465d-90ef-a4b4552d8e2c",
//     user_slug: "91a56791-552c-4028-a81f-98895f504a5e",
//     order_slug: null,
//     gateway_transaction_id: "tr_1QNeoYI5pBhNo0COtdzqsiEX",
//     transaction_type: "debit",
//     previous_amount: 100,
//     new_amount: 80,
//     transaction_amount: 20,
//     is_tip: false,
//     status: "succeed",
//     description: "Withdraw of amount $20",
//     created_at: "2024-11-21T18:09:14.000Z",
//     user: {
//       id: 43,
//       slug: "91a56791-552c-4028-a81f-98895f504a5e",
//       user_type: "RESTAURANT",
//       firstname: null,
//       lastname: null,
//       email: "sarfoelijah1@gmail.com",
//       mobile_no: "+233532871143",
//     },
//   },
//   {
//     slug: "3b5de419-9d5a-4713-bea1-9ccbb1647789",
//     user_slug: "91a56791-552c-4028-a81f-98895f504a5e",
//     order_slug: null,
//     gateway_transaction_id: "tr_1QNeo8I5pBhNo0COZRbbPDhe",
//     transaction_type: "debit",
//     previous_amount: 128,
//     new_amount: 100,
//     transaction_amount: 28,
//     is_tip: false,
//     status: "succeed",
//     description: "Withdraw of amount $28",
//     created_at: "2024-11-21T18:08:50.000Z",
//     user: {
//       id: 43,
//       slug: "91a56791-552c-4028-a81f-98895f504a5e",
//       user_type: "RESTAURANT",
//       firstname: null,
//       lastname: null,
//       email: "sarfoelijah1@gmail.com",
//       mobile_no: "+233532871143",
//     },
//   },
//   {
//     slug: "20b84e0d-2934-4727-a0b1-9f1884228500",
//     user_slug: "91a56791-552c-4028-a81f-98895f504a5e",
//     order_slug: null,
//     gateway_transaction_id: "tr_1QNenqI5pBhNo0CO4LxSifvI",
//     transaction_type: "debit",
//     previous_amount: 140,
//     new_amount: 128,
//     transaction_amount: 12,
//     is_tip: false,
//     status: "succeed",
//     description: "Withdraw of amount $12",
//     created_at: "2024-11-21T18:08:30.000Z",
//     user: {
//       id: 43,
//       slug: "91a56791-552c-4028-a81f-98895f504a5e",
//       user_type: "RESTAURANT",
//       firstname: null,
//       lastname: null,
//       email: "sarfoelijah1@gmail.com",
//       mobile_no: "+233532871143",
//     },
//   },
//   {
//     slug: "926e51c2-a463-4767-bdac-869b83c0a470",
//     user_slug: "c84d9188-c726-49d0-a292-7987e6152f77",
//     order_slug: "30518bc8-70c9-47f0-a249-5e627a29b123",
//     gateway_transaction_id: "tpavzckweo",
//     transaction_type: "debit",
//     previous_amount: 0,
//     new_amount: 0,
//     transaction_amount: 42.5,
//     is_tip: false,
//     status: "success",
//     description:
//       "Card Charge of amount $42.5 for order# 30518bc8-70c9-47f0-a249-5e627a29b123",
//     created_at: "2024-11-21T11:56:17.000Z",
//     user: {
//       id: 37,
//       slug: "c84d9188-c726-49d0-a292-7987e6152f77",
//       user_type: "CUSTOMER",
//       firstname: "customer",
//       lastname: "one",
//       email: "customer1@yopmail.com",
//       mobile_no: "+11234567988",
//     },
//   },
//   {
//     slug: "4bd9e375-3629-4364-b346-92c94df67ed4",
//     user_slug: "9a027016-4cfa-42fa-95bc-18e52b6fe4a2",
//     order_slug: null,
//     gateway_transaction_id: "TRF_dabcxz1utj5m62kr",
//     transaction_type: "debit",
//     previous_amount: 2850.95,
//     new_amount: 2845.95,
//     transaction_amount: 5,
//     is_tip: false,
//     status: "pending",
//     description: "Withdraw of amount $5",
//     created_at: "2024-11-21T11:50:52.000Z",
//     user: {
//       id: 38,
//       slug: "9a027016-4cfa-42fa-95bc-18e52b6fe4a2",
//       user_type: "RESTAURANT",
//       firstname: null,
//       lastname: null,
//       email: "restautrant1@yopmail.com",
//       mobile_no: "+11234567898",
//     },
//   },
//   {
//     slug: "4cb4f7ca-1685-4b23-8f47-da4f0bae3308",
//     user_slug: "91a56791-552c-4028-a81f-98895f504a5e",
//     order_slug: "938cfbf1-6a78-4b61-b743-8549f438acad",
//     gateway_transaction_id: "TX_ece3eafb-40e8-4011-84b4-f82abac4534b",
//     transaction_type: "credit",
//     previous_amount: 70,
//     new_amount: 140,
//     transaction_amount: 70,
//     is_tip: false,
//     status: "succeed",
//     description:
//       "Credit of amount $70 against order#938cfbf1-6a78-4b61-b743-8549f438acad",
//     created_at: "2024-11-21T08:42:51.000Z",
//     user: {
//       id: 43,
//       slug: "91a56791-552c-4028-a81f-98895f504a5e",
//       user_type: "RESTAURANT",
//       firstname: null,
//       lastname: null,
//       email: "sarfoelijah1@gmail.com",
//       mobile_no: "+233532871143",
//     },
//   },
//   {
//     slug: "ea10f38b-d1eb-48e5-9f47-d271a24a0248",
//     user_slug: "25c159df-ebac-46ba-b8bc-ae18839a6091",
//     order_slug: "938cfbf1-6a78-4b61-b743-8549f438acad",
//     gateway_transaction_id: "TX_70ead0f0-a3b1-42d8-9bc5-d8ec0552e960",
//     transaction_type: "credit",
//     previous_amount: 20,
//     new_amount: 40,
//     transaction_amount: 20,
//     is_tip: true,
//     status: "succeed",
//     description:
//       "Credit of amount $20 against order#938cfbf1-6a78-4b61-b743-8549f438acad",
//     created_at: "2024-11-21T08:42:51.000Z",
//     user: {
//       id: 83,
//       slug: "25c159df-ebac-46ba-b8bc-ae18839a6091",
//       user_type: "RIDER",
//       firstname: "NANA",
//       lastname: "ONE",
//       email: "princetest37@gmail.com",
//       mobile_no: "+233200844798",
//     },
//   },
//   {
//     slug: "6348815b-de43-409d-950b-02ab709e4adb",
//     user_slug: "93c7088d-346e-45b4-bbae-db13a89b9900",
//     order_slug: "938cfbf1-6a78-4b61-b743-8549f438acad",
//     gateway_transaction_id: "pi_3QNMiMI5pBhNo0CO1nKHRzxE",
//     transaction_type: "debit",
//     previous_amount: 0,
//     new_amount: 0,
//     transaction_amount: 105,
//     is_tip: false,
//     status: "succeeded",
//     description:
//       "Card Charge of amount $105 for order# 938cfbf1-6a78-4b61-b743-8549f438acad",
//     created_at: "2024-11-20T22:49:39.000Z",
//     user: {
//       id: 34,
//       slug: "93c7088d-346e-45b4-bbae-db13a89b9900",
//       user_type: "CUSTOMER",
//       firstname: "Sam",
//       lastname: "Obeng",
//       email: "obeng732@gmail.com",
//       mobile_no: "+18483912393",
//     },
//   },
//   {
//     slug: "ad3a5316-733b-4398-bf50-b9687dad90ca",
//     user_slug: "91a56791-552c-4028-a81f-98895f504a5e",
//     order_slug: "fad6ef3f-9c6a-43ed-b692-419e644f962a",
//     gateway_transaction_id: "TX_20d2a5cc-dc20-4926-8be3-c738650f3435",
//     transaction_type: "credit",
//     previous_amount: 0,
//     new_amount: 70,
//     transaction_amount: 70,
//     is_tip: false,
//     status: "succeed",
//     description:
//       "Credit of amount $70 against order#fad6ef3f-9c6a-43ed-b692-419e644f962a",
//     created_at: "2024-11-20T13:26:13.000Z",
//     user: {
//       id: 43,
//       slug: "91a56791-552c-4028-a81f-98895f504a5e",
//       user_type: "RESTAURANT",
//       firstname: null,
//       lastname: null,
//       email: "sarfoelijah1@gmail.com",
//       mobile_no: "+233532871143",
//     },
//   },
//   {
//     slug: "1490bdf8-bf11-4725-af35-36eddecf0a9a",
//     user_slug: "25c159df-ebac-46ba-b8bc-ae18839a6091",
//     order_slug: "fad6ef3f-9c6a-43ed-b692-419e644f962a",
//     gateway_transaction_id: "TX_b3c88081-f4d3-4662-85df-1ba3046f7158",
//     transaction_type: "credit",
//     previous_amount: 0,
//     new_amount: 20,
//     transaction_amount: 20,
//     is_tip: true,
//     status: "succeed",
//     description:
//       "Credit of amount $20 against order#fad6ef3f-9c6a-43ed-b692-419e644f962a",
//     created_at: "2024-11-20T13:26:13.000Z",
//     user: {
//       id: 83,
//       slug: "25c159df-ebac-46ba-b8bc-ae18839a6091",
//       user_type: "RIDER",
//       firstname: "NANA",
//       lastname: "ONE",
//       email: "princetest37@gmail.com",
//       mobile_no: "+233200844798",
//     },
//   },
//   {
//     slug: "d0b84f9d-4fc7-4679-9369-4929afc1bda8",
//     user_slug: "690be2c6-21e8-435d-b245-939f0b60678c",
//     order_slug: "a4c4ea76-40e2-4bf1-bed1-17733690c9a3",
//     gateway_transaction_id: "pi_3QNDIuI5pBhNo0CO1MBCjIdk",
//     transaction_type: "debit",
//     previous_amount: 0,
//     new_amount: 0,
//     transaction_amount: 70,
//     is_tip: false,
//     status: "succeeded",
//     description:
//       "Card Charge of amount $70 for order# a4c4ea76-40e2-4bf1-bed1-17733690c9a3",
//     created_at: "2024-11-20T12:46:45.000Z",
//     user: {
//       id: 113,
//       slug: "690be2c6-21e8-435d-b245-939f0b60678c",
//       user_type: "CUSTOMER",
//       firstname: null,
//       lastname: null,
//       email: "kwakuntiamoah86@gmail.com",
//       mobile_no: null,
//     },
//   },
//   {
//     slug: "0921242b-48bf-4a50-ae56-7acb450ed461",
//     user_slug: "93c7088d-346e-45b4-bbae-db13a89b9900",
//     order_slug: "fad6ef3f-9c6a-43ed-b692-419e644f962a",
//     gateway_transaction_id: "pi_3QN3KXI5pBhNo0CO1ARYnZgo",
//     transaction_type: "debit",
//     previous_amount: 0,
//     new_amount: 0,
//     transaction_amount: 105,
//     is_tip: false,
//     status: "succeeded",
//     description:
//       "Card Charge of amount $105 for order# fad6ef3f-9c6a-43ed-b692-419e644f962a",
//     created_at: "2024-11-20T02:07:46.000Z",
//     user: {
//       id: 34,
//       slug: "93c7088d-346e-45b4-bbae-db13a89b9900",
//       user_type: "CUSTOMER",
//       firstname: "Sam",
//       lastname: "Obeng",
//       email: "obeng732@gmail.com",
//       mobile_no: "+18483912393",
//     },
//   },
//   {
//     slug: "6047263b-cb3f-4945-8631-c10d3de7b409",
//     user_slug: "9a027016-4cfa-42fa-95bc-18e52b6fe4a2",
//     order_slug: "26d2b0bd-1fc2-4e9d-9098-a7f2dc72d2b6",
//     gateway_transaction_id: "TX_8b2fc1cc-e962-4d1c-8071-68a7824d0f00",
//     transaction_type: "credit",
//     previous_amount: 2800.95,
//     new_amount: 2850.95,
//     transaction_amount: 50,
//     is_tip: false,
//     status: "succeed",
//     description:
//       "Credit of amount $50 against order#26d2b0bd-1fc2-4e9d-9098-a7f2dc72d2b6",
//     created_at: "2024-10-21T07:52:51.000Z",
//     user: {
//       id: 38,
//       slug: "9a027016-4cfa-42fa-95bc-18e52b6fe4a2",
//       user_type: "RESTAURANT",
//       firstname: null,
//       lastname: null,
//       email: "restautrant1@yopmail.com",
//       mobile_no: "+11234567898",
//     },
//   },
//   {
//     slug: "ba9cc6a3-280a-40c1-8982-12400ac14142",
//     user_slug: "ed4f1873-8410-4877-8899-d84c9f217ae4",
//     order_slug: "26d2b0bd-1fc2-4e9d-9098-a7f2dc72d2b6",
//     gateway_transaction_id: "TX_d1b0029a-dcd9-4f4b-adb2-42b59c683bba",
//     transaction_type: "credit",
//     previous_amount: 100,
//     new_amount: 120,
//     transaction_amount: 20,
//     is_tip: true,
//     status: "succeed",
//     description:
//       "Credit of amount $20 against order#26d2b0bd-1fc2-4e9d-9098-a7f2dc72d2b6",
//     created_at: "2024-10-21T07:52:51.000Z",
//     user: {
//       id: 79,
//       slug: "ed4f1873-8410-4877-8899-d84c9f217ae4",
//       user_type: "RIDER",
//       firstname: "rider",
//       lastname: "two",
//       email: "rider2@yopmail.com",
//       mobile_no: "+11234567852",
//     },
//   },
//   {
//     slug: "66debd5e-9357-4c3b-874c-8cded4bb711a",
//     user_slug: "c84d9188-c726-49d0-a292-7987e6152f77",
//     order_slug: "26d2b0bd-1fc2-4e9d-9098-a7f2dc72d2b6",
//     gateway_transaction_id: "pi_3QCGP8I5pBhNo0CO0lFvlSCq",
//     transaction_type: "debit",
//     previous_amount: 0,
//     new_amount: 0,
//     transaction_amount: 90,
//     is_tip: false,
//     status: "succeeded",
//     description:
//       "Card Charge of amount $90 for order# 26d2b0bd-1fc2-4e9d-9098-a7f2dc72d2b6",
//     created_at: "2024-10-21T07:51:55.000Z",
//     user: {
//       id: 37,
//       slug: "c84d9188-c726-49d0-a292-7987e6152f77",
//       user_type: "CUSTOMER",
//       firstname: "customer",
//       lastname: "one",
//       email: "customer1@yopmail.com",
//       mobile_no: "+11234567988",
//     },
//   },
//   {
//     slug: "38a714bf-ea5f-4319-92fe-0983c91ccc55",
//     user_slug: "9a027016-4cfa-42fa-95bc-18e52b6fe4a2",
//     order_slug: "760c5950-e0de-4575-9d38-49405e93a0d4",
//     gateway_transaction_id: "TX_d0c93f2f-4407-46d2-9a6a-7627602ce288",
//     transaction_type: "credit",
//     previous_amount: 2750.95,
//     new_amount: 2800.95,
//     transaction_amount: 50,
//     is_tip: false,
//     status: "succeed",
//     description:
//       "Credit of amount $50 against order#760c5950-e0de-4575-9d38-49405e93a0d4",
//     created_at: "2024-10-18T14:52:24.000Z",
//     user: {
//       id: 38,
//       slug: "9a027016-4cfa-42fa-95bc-18e52b6fe4a2",
//       user_type: "RESTAURANT",
//       firstname: null,
//       lastname: null,
//       email: "restautrant1@yopmail.com",
//       mobile_no: "+11234567898",
//     },
//   },
// ];

const TransactionHistoryScreen = ({ setPage }) => {
  const dispatch = useDispatch();

  // const [data, setData] = useState([]);
  const [indicationMessage, setIndicationMessage] = useState("loading...");
  const [rowData, setRowData] = useState([]);

  const [loading, setLoading] = useState(false);

  const [startDate, setStartDate] = useState("");
  const [endDate, setEndDate] = useState("");
  const [searchQuery, setSearchQuery] = useState("");

  const [currentPage, setCurrentPage] = useState(1); // todo
      const [pageSize, setPageSize] = useState(10); // todo
      const [totalRecords, setTotalRecords] = useState(0); // todo
      const [totalPages, setTotalPages] = useState(0); // todo
      const [dataFetched, setDataFetched] = useState(false); // todo

      const { data, isSuccess, isLoading, isFetching, refetch } =
      useGetAllTxnHistoryQuery(
             {
               search: searchQuery.length >= 3 ? searchQuery : "",
               start_date: startDate != null ? startDate : "",
               end_date: endDate != null ? endDate : "",
               limit: pageSize,
               page: currentPage,
             },
             { refetchOnMountOrArgChange: true }
           );

             useEffect(() => {
                 if (data) {
                   // console.log(data);
                   setPageSize(data.links.per_page);
                   setCurrentPage(data.links.current);
                   setTotalRecords(data.links.total_records);
                   setTotalPages(data.links.total);
                   setRowData(data.data);
                   setDataFetched(true);
                 }
               }, [data]);

  // const debouncedSearch = useCallback(
  //   debounce((query) => {
  //     console.log("Search query:", query);
  //     // local search logic
  //     const filtered = dummy_data.filter((item) =>
  //       [item.firstname, item.lastname, item.username, item.email]
  //         .filter(Boolean) // Remove null/undefined fields
  //         .some((field) => field.toLowerCase().includes(query.toLowerCase()))
  //     );

  //     setRowData(filtered);

  //     // Trigger your search logic or API here
  //   }, 500), // Adjust debounce delay as needed
  //   []
  // );

  const handleFilter = (event) => {
    // console.log(startDate);
    // console.log(endDate);
    setStartDate(startDate);
    setEndDate(endDate)
  };

  

  function indication() {
    // return something here
    // return 'Table is empty';
    return indicationMessage;
  }

  const columns = [
    {
      dataField: "name",
      text: "Name",
      sort: true,
      headerStyle: {
        width: "100px",
      },
      formatter: (cellContent, row) => {
        const username = row.user.firstname + row.user.lastname;

        return <span className="lc-1">{`${username}`}</span>;
      },
    },
    {
      dataField: "email",
      text: "Email",
      // sort: true,
      headerStyle: {
        width: "100px",
      },
      formatter: (cellContent, row) => {
        return <span className="lc-1"> {row.user.email}</span>;
      },
    },
    {
      dataField: "gateway_transaction_id",
      text: "Txn Id",
      // sort: true,
      headerAlign: "center",
      align: "center",
      headerStyle: {
        width: "100px",
      },
      formatter: (cellContent, row) => {
        return (
          <Badge pill bg="" className="p-2 bg-secondary">
            {row.gateway_transaction_id}
          </Badge>
        );
      },
    },
    {
      dataField: "transaction_amount",
      text: "Txn Amount",
      // sort: true,
      headerAlign: "center",
      align: "center",
      headerStyle: {
        width: "100px",
      },
    },
    {
      dataField: "is_tip",
      text: "Tip",
      // sort: true,
      headerAlign: "center",
      align: "center",
      headerStyle: {
        width: "100px",
      },
      formatter: (cellContent, row) => {
        if (row.is_tip) {
          return (
            <Badge pill bg="" className="p-2 bg-dark-subtle">
              <MdOutlineDone color="green" size={16} />
            </Badge>
          );
        } else {
          return (
            <Badge pill bg="" className="p-2 bg-dark-subtle">
              <RxCross1 color="red" size={16} />
            </Badge>
          );
        }
      },
    },
    {
      dataField: "status",
      text: "Txn Status",
      // sort: true,
      headerAlign: "center",
      align: "center",
      headerStyle: {
        width: "100px",
      },
      formatter: (cellContent, row) => {
        if (row.status === "pending") {
          return (
            <Badge
              pill
              bg=""
              className="p-2 bg-dark-subtle text-capitalize text-secondary-emphasis"
            >
              {row.status}
            </Badge>
          );
        } else {
          return (
            <Badge
              pill
              bg=""
              className="p-2 bg-dark-subtle text-capitalize text-success"
            >
              {row.status}
            </Badge>
          );
        }
      },
    },
    {
      dataField: "createdAt",
      text: "Date",
      // sort: true,
      headerAlign: "center",
      align: "center",
      headerStyle: {
        width: "100px",
      },
      formatter: (cellContent, row) => {
        const agoFormat = moment(row.created_at).fromNow();
        // return <span className=" fs-7"> {agoFormat}</span>;
        return (
          <OverlayTrigger
            placement="top"
            delay={{ show: 250, hide: 400 }}
            overlay={renderTooltip(row.created_at)}
          >
            <span className=" fs-7 cursor-pointer"> {agoFormat}</span>
          </OverlayTrigger>
        );
      },
    },
  ];

  return (
    <div className={styles.MainContainer}>
      <div className={styles.headerContainer}>
        <Row className="w-100 align-items-center">
          <Col md={6}>
            <div>
              <SubTitle text={"Transaction History"} classes="text-black" />
            </div>
          </Col>

          <Col md={6}>
            <div className="d-flex flex-row gap-2">
              <Input
                type="date"
                placeholder="Start Date"
                value={startDate}
                onChange={(e) => setStartDate(e.target.value)}
              />
              <Input
                type="date"
                placeholder="End Date"
                value={endDate}
                onChange={(e) => setEndDate(e.target.value)}
              />
              {/* <Button
                text="Filter"
                classes={`${styles.cardBtn} w-auto`}
                onClick={handleFilter}
              ></Button> */}
            </div>
          </Col>
        </Row>

        {
          // <Row>
          //   <Col md={12}>
          //     <Button
          //       classes={`${styles.cardBtn} `}
          //       text="Add User"
          //       onClick={handleAddUser}
          //     ></Button>
          //   </Col>
          // </Row>
        }
      </div>

      <div className={styles.mainTable}>
      {!dataFetched || !isSuccess ? (
          <CustomLoader />
        ) : (
        <div className={styles.mainTable}>
          {<Table data={rowData} columns={columns} indication={indication}
          
          totalRecords={totalRecords} // todo
          totalPages={totalPages} // todo
          pageSize={pageSize} // todo
          currentPage={currentPage} // todo
          setCurrentPage={setCurrentPage} // todo
          setPageSize={setPageSize} // todo
          refetch={refetch} // todo
          />}
        </div>
      )}
      </div>
    </div>
  );
};

const renderTooltip = (date) => (
  <Tooltip id="button-tooltip">
    {moment(date).format("YYYY-MM-DD HH:mm:ss")}
  </Tooltip>
);

export default TransactionHistoryScreen;
