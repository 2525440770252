import React, { useState } from "react";
import { Col, Form, InputGroup, Row } from "react-bootstrap";
import {
  Button,
  Checbox,
  Input,
  SubTitle,
} from "../../Components/CustomComponents";
import { USER_TYPES } from "../../constants";
import styles from "./styles/sendNotificationScreen.module.scss";
import { useSentNotificationMutation } from "../../Data/services/notificationApi";
import Toast, { SuccessToast } from "../../Hooks/useToast";
import { Controller, useForm } from "react-hook-form";

const SendNotificationScreen = ({ setPage }) => {
  const { control, handleSubmit, reset, formState: { errors },  } = useForm({
    defaultValues: {
      title: "",
      notification: "",
      user_type: [], 
    },
  });

  // const [title, setTitle] = useState("");
  // const [notification, setNotification] = useState("");
  // const [selectedUserTypes, setSelectedUserTypes] = useState([]);

  const handleChangeUserType = (label) => {
    // console.log(label);
    // setSelectedUserTypes((prevSelected) => {
    //   if (prevSelected.includes(label)) {
    //     return prevSelected.filter((item) => item !== label);
    //   } else {
    //     return [...prevSelected, label];
    //   }
    // });
  };


  const [sendNotification, { isLoading }] = useSentNotificationMutation();

  const handleSendNotification = async (data) => {
    // console.log(title);
    // console.log(notification);
    // console.log(selectedUserTypes);

    console.log(data)

    // const data = {
    //   title,
    //   message: notification,
    //   user_type: selectedUserTypes,
    // };

    try {
      const response = await sendNotification(data);

      if (response.data.code === 200) {
        SuccessToast(response?.data.message);
      }
    } catch (error) {
      if (error?.response && error?.response?.data?.message) {
        Toast(error?.response?.data?.message, "error");
      } else {
        Toast("An error occurred during login", "error");
      }
    }
  };

  return (
    <div className={styles.MainContainer}>
      {errors
        ? errors.title?.message
          ? Toast(errors.title?.message, "error")
          : Toast(errors.notification?.message, "error")
        : null}
      <div className={styles.headerContainer}>
        <div>
          <SubTitle text={"Send Notification"} classes="text-black" />
        </div>
        <Row>
          <Col md={12}>
            <Button
              classes={`${styles.cardBtn} `}
              text="Send"
              onClick={handleSubmit(handleSendNotification)}
            ></Button>
          </Col>
        </Row>
      </div>

      <div className="mt-3">
        <Form>
          <Controller
            control={control}
            name="title"
            rules={{
              required: "Title is required.",
            }}
            render={({ field: { onChange, onBlur, value, ref } }) => (
              <InputGroup className="mb-3">
                <Input
                  placeholder="Title"
                  type="text"
                  value={value}
                  onChange={onChange}
                />
              </InputGroup>
            )}
          />

          <Controller
            control={control}
            name="message"
            rules={{
              required: "Notification Message is required.",
            }}
            render={({ field: { onChange, onBlur, value, ref } }) => (
              <InputGroup className="mb-3">
                <Input
                  placeholder="Notification"
                  type="textarea"
                  as="textarea"
                  rows={5}
                  value={value}
                  onChange={onChange}
                />
              </InputGroup>
            )}
          />
          
          <Controller 
          control={control}
          name="user_type"
          render={({ field: { onChange, value } }) => (
          <InputGroup className="mb-3">
            <lable>
              Select User Type
              <Checbox
                label={USER_TYPES.CUSTOMER}
                classes="my-2"
                onChange={(e) => {
                  const isChecked = e.target.checked;
                  const newValue = isChecked 
                    ? [...(value || []), USER_TYPES.CUSTOMER] 
                    : (value || []).filter(type => type !== USER_TYPES.CUSTOMER);
                  onChange(newValue);
                }}
              />
              <Checbox
                label={USER_TYPES.RIDER}
                classes="my-2"
                onChange={(e) => {
                  const isChecked = e.target.checked;
                  const newValue = isChecked 
                    ? [...(value || []), USER_TYPES.RIDER] 
                    : (value || []).filter(type => type !== USER_TYPES.RIDER);
                  onChange(newValue);
                }}
              />
              <Checbox
                label={USER_TYPES.RESTAURANT}
                classes="my-2"
                onChange={(e) => {
                  const isChecked = e.target.checked;
                  const newValue = isChecked 
                    ? [...(value || []), USER_TYPES.RESTAURANT] 
                    : (value || []).filter(type => type !== USER_TYPES.RESTAURANT);
                  onChange(newValue);
                }}
              />
            </lable>
          </InputGroup>
          
          )}
          />
        </Form>
      </div>
    </div>
  );
};

export default SendNotificationScreen;
