import axios from "axios";
import React, { useEffect, useState } from "react";
import "react-bootstrap-table-next/dist/react-bootstrap-table2.min.css";
import "react-bootstrap-table2-toolkit/dist/react-bootstrap-table2-toolkit.min.css";
import { SubTitle } from "../../Components/CustomComponents/index.js";
import Table from "../../Components/Table/Table.js";
import Toast, { SuccessToast } from "../../Hooks/useToast.js";
import styles from "./styles/user_management.module.scss";
// import { ToastContainer, toast } from "react-toastify";
import { AiOutlineSolution } from "react-icons/ai";
//
import { useDispatch } from "react-redux"; // for getting and setting data into local redux
// import CustomerBlockModal from "../../Components/Modals/CustomerBlockModal";
import moment from "moment";
import { Badge, Col, OverlayTrigger, Row, Tooltip } from "react-bootstrap";
import ConfirmModal from "../../Components/Modals/ComfirmModal.js";
import {
  useGetAllReportsQuery,
  useUpdateResolveStatusMutation,
} from "../../Data/services/reportsApi.js";
import CustomLoader from "../../Components/CustomComponents/CustomLoader.js";

// const dummy_data = [
//   {
//     id: 3,
//     slug: "3592d60d-c941-4671-bfab-acdf39cded2e",
//     user_slug: "9a027016-4cfa-42fa-95bc-18e52b6fe4a2",
//     user: {
//       image_url: "https://api.wow.trangotechdevs.com/1726831869130.jpg",
//       name: "restautrant1",
//       email: "restautrant1@yopmail.com",
//       mobile_no: "+11234567898",
//     },
//     report_type: "Refund Issue",
//     description: "testing",
//     status: "pending",
//     // status: "resolved",
//     created_at: "2024-12-04T09:23:13.000Z",
//   },
// ];

const ReportsScreen = ({ setPage }) => {
  const dispatch = useDispatch();

  // const [data, setData] = useState([]);
  const [indicationMessage, setIndicationMessage] = useState("loading...");
  const [rowData, setRowData] = useState([]);
  const [editedData, setEditedData] = useState("");

  const [currentPage, setCurrentPage] = useState(1); // todo
  const [pageSize, setPageSize] = useState(10); // todo
  const [totalRecords, setTotalRecords] = useState(0); // todo
  const [totalPages, setTotalPages] = useState(0); // todo
  const [dataFetched, setDataFetched] = useState(false); // todo

  const [loading, setLoading] = useState(false);
  const [confirmModal, setConfirmModal] = useState(false);

  const [searchQuery, setSearchQuery] = useState("");

  const { data, isSuccess, isLoading, isFetching, refetch } =
    useGetAllReportsQuery(
      {
        search: searchQuery.length >= 3 ? searchQuery : "",
        limit: pageSize,
        page: currentPage,
      },
      { refetchOnMountOrArgChange: true }
    );

  useEffect(() => {
    if (data) {
      // console.log(data);
      setPageSize(data.links.per_page);
      setCurrentPage(data.links.current);
      setTotalRecords(data.links.total_records);
      setTotalPages(data.links.total);
      setRowData(data.data);
      setDataFetched(true);
    }
  }, [data]);

  const handleResolveModalOpen = () => {
    setConfirmModal(true);
  };

  const handleResolveModalClose = () => {
    setConfirmModal(false);
    setEditedData("");
  };

  const [updateResolveStatus, { isLoading: isUpdating }] =
    useUpdateResolveStatusMutation();

  const handleResolve = async (slug) => {
    // console.log(slug);
    setLoading(true);

    const data = {
      status: "resolved",
    };

    try {
      const response = await updateResolveStatus({slug, data});

      // console.log(response);

      if (response.data.code === 200) {
        await refetch();
        setLoading(false);
        handleResolveModalClose();
        SuccessToast(response.data?.message);
      }
    } catch (error) {
      // console.log(error);
      Toast(error.message, "error");
    }
  };

  function indication() {
    // return something here
    // return 'Table is empty';
    return indicationMessage;
  }

  const columns = [
    {
      dataField: "user",
      text: "User Name",
      sort: true,
      headerStyle: {
        width: "100px",
      },
      formatter: (cellContent, row) => {
        // return row.firstname + row.lastname;
        return (
          <div className="d-flex flex-row justify-content-start align-items-center gap-2">
            <img
              alt="user"
              src={row.user.image_url}
              loading="lazy"
              width={48}
              height={48}
              className="rounded-circle object-fit-cover"
            />
            <div className="lc-1"> {`${row.user.name}`} </div>
          </div>
        );
      },
    },
    {
      dataField: "email",
      text: "Email",
      // sort: true,
      headerStyle: {
        width: "100px",
      },
      formatter: (cellContent, row) => {
        return <span className="lc-1"> {row.user.email}</span>;
      },
    },
    {
      dataField: "mobile_no",
      text: "Phone",
      // sort: true,
      headerAlign: "center",
      align: "center",
      headerStyle: {
        width: "100px",
      },
      formatter: (cellContent, row) => {
        return <span className="lc-1"> {row.user.mobile_no}</span>;
      },
    },
    {
      dataField: "report_type",
      text: "Type",
      // sort: true,
      headerAlign: "center",
      align: "center",
      headerStyle: {
        width: "100px",
      },
    },
    {
      dataField: "description",
      text: "Description",
      // sort: true,
      headerAlign: "center",
      align: "center",
      headerStyle: {
        width: "100px",
      },
    },
    {
      dataField: "status",
      text: "Status",
      sort: true,
      headerAlign: "center",
      align: "center",
      headerStyle: {
        width: "100px",
      },
      formatter: (cellContent, row) => {
        if (row.status === "pending") {
          return (
            <Badge
              pill
              bg=""
              className="p-2 bg-dark-subtle text-capitalize text-secondary-emphasis"
            >
              {row.status}
            </Badge>
          );
        } else {
          return (
            <Badge
              pill
              bg=""
              className="p-2 bg-dark-subtle text-capitalize text-success"
            >
              {row.status}
            </Badge>
          );
        }
      },
    },
    {
      dataField: "createdAt",
      text: "Date",
      // sort: true,
      headerAlign: "center",
      align: "center",
      headerStyle: {
        width: "100px",
      },
      formatter: (cellContent, row) => {
        const agoFormat = moment(row.created_at).fromNow();
        // return <span className=" fs-7"> {agoFormat}</span>;
        return (
          <OverlayTrigger
            placement="top"
            delay={{ show: 250, hide: 400 }}
            overlay={renderTooltip(row.created_at)}
          >
            <span className=" fs-7 cursor-pointer"> {agoFormat}</span>
          </OverlayTrigger>
        );
      },
    },
    {
      dataField: "actions",
      text: "Actions",
      headerAlign: "center",
      align: "center",
      headerStyle: {
        width: "100px",
      },
      formatter: (cellContent, row) => {
        // console.log(row)
        if (row.slug) {
          return (
            <div className="d-flex justify-content-center align-items-center ">
              <div
                className={`d-flex justify-content-center align-items-center  rounded-3 p-2 mx-2 ${
                  row.status !== "pending"
                    ? "text-bg-secondary"
                    : "bg-success-hover"
                }`}
                role={row.status === "pending" ? "button" : "div"}
                onClick={() => {
                  if (row.status === "pending") {
                    setEditedData(row);
                    handleResolveModalOpen();
                  }
                }}
              >
                <span className={`d-flex align-items-center pe-1`}>
                  <AiOutlineSolution />
                  <span className="d-none d-xl-block">Resolve</span>
                </span>
              </div>
            </div>
          );
        }
      },
    },
  ];

  return (
    <div className={styles.MainContainer}>
      {confirmModal ? (
        <ConfirmModal
          handleDeleteModalClose={handleResolveModalClose}
          data={editedData}
          onResolve={handleResolve}
          loading={loading}
        />
      ) : (
        ""
      )}

      <div className={styles.headerContainer}>
        <Row className="w-100 align-items-center">
          <Col md={6}>
            <div>
              <SubTitle text={"Reports"} classes="text-black" />
            </div>
          </Col>

          <Col md={6}></Col>
        </Row>

        {
          // <Row>
          //   <Col md={12}>
          //     <Button
          //       classes={`${styles.cardBtn} `}
          //       text="Add User"
          //       onClick={handleAddUser}
          //     ></Button>
          //   </Col>
          // </Row>
        }
      </div>

      <div className={styles.mainTable}>
        {!dataFetched || !isSuccess ? (
          <CustomLoader />
        ) : (
          <div className={styles.mainTable}>
            {
              <Table
                data={rowData}
                columns={columns}
                indication={indication}
                totalRecords={totalRecords} // todo
                totalPages={totalPages} // todo
                pageSize={pageSize} // todo
                currentPage={currentPage} // todo
                setCurrentPage={setCurrentPage} // todo
                setPageSize={setPageSize} // todo
                refetch={refetch} // todo
              />
            }
          </div>
        )}
      </div>
    </div>
  );
};

const renderTooltip = (date) => (
  <Tooltip id="button-tooltip">
    {moment(date).format("YYYY-MM-DD HH:mm:ss")}
  </Tooltip>
);

export default ReportsScreen;
