import _ from "lodash";
import React, { useEffect, useState } from "react";
import { Col, Row } from "react-bootstrap";
import { useSelector } from "react-redux";
import ClipLoader from "react-spinners/ClipLoader";
import { Button, Input, SubTitle } from "../../Components/CustomComponents";
import {
  useGetAppSettingsQuery,
  useUpdateAppSettingsMutation,
} from "../../Data/services/settingApi";
import Toast, { SuccessToast } from "../../Hooks/useToast";
import styles from "./styles/StaticPage.module.scss";

const AppSettings = () => {
  const [deliveryCharges, setDeliveryCharges] = useState("");
  const [platformFee, setPlatformFee] = useState("");
  const [gst, setGst] = useState("");

  const { data, isSuccess } = useGetAppSettingsQuery({
    refetchOnMountOrArgChange: true,
  });

  var settingData = useSelector((state) => state.settings.appSettings);

  useEffect(() => {
    if (!_.isEmpty(data)) {
      setDeliveryCharges(settingData.delivery_charges);
      setPlatformFee(settingData.platform_fee);
      setGst(settingData.gst);
    }
  }, [data]);

  const handleDC = (e) => {
    // console.log(e.target.value);
    setDeliveryCharges(e.target.value);
  };
  const handlePF = (e) => {
    // console.log(e.target.value);
    setPlatformFee(e.target.value);
  };
  const handleGST = (e) => {
    // console.log(e.target.value);
    setGst(e.target.value);
  };

  const [updateAppSettings, { isLoading }] = useUpdateAppSettingsMutation();

  const handleSaveText = async () => {
    // console.log(value
    // console.log(deliveryCharges);
    // console.log(platformFee);
    // console.log(gst);

    const data = {
      delivery_charges: deliveryCharges,
      platform_fee: platformFee,
      gst: gst,
    };

    // console.log(data);

    try {
      const response = await updateAppSettings(data);

      console.log(response);

      if (response.error && !response.data) {
        Toast(response.error.data.message, "error");
      }

      if (!response.error && response.data.code === 200) {
        SuccessToast(response?.data?.message);
      }
    } catch (error) {
      if (error?.response && error?.response?.data?.message) {
        Toast(error?.response?.data?.message, "error");
      } else {
        Toast("An error occurred during login", "error");
      }
    }
  };

  if (!isSuccess) {
    return (
      <div className={`${styles.StaticPage} text-center align-content-center`}>
        <ClipLoader color="#1C75BC" />
      </div>
    );
  }
  return (
    <div className={styles.StaticPage}>
      <Row>
        <Col sm={10}>
          <SubTitle text="App Settings" classes="mb-4" />
        </Col>
        <Col sm={2}>
          <Button
            classes={`${styles.cardBtn} `}
            text="Save"
            onClick={handleSaveText}
          ></Button>
        </Col>
      </Row>
      <p className="">Set your payment parameters.</p>
      <Row>
        <Col md={12}>
          <p className="m-0 ">Delivery Charges</p>
          <Input
            classes="mt-2 mb-3"
            type="number"
            placeholder="Enter charges"
            value={deliveryCharges}
            onChange={handleDC}
          />
          <p className="m-0 ">Platform Fee</p>
          <Input
            classes="mt-2 mb-3"
            type="number"
            placeholder="Enter charges"
            value={platformFee}
            onChange={handlePF}
          />
          <p className="m-0 ">GST (Government Sales Tax)</p>
          <Input
            classes="mt-2 mb-3"
            type="number"
            placeholder="Enter charges"
            value={gst}
            onChange={handleGST}
          />
        </Col>
      </Row>
    </div>
  );
};

export default AppSettings;
