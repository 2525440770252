import React, { useState } from "react";

import Login from "../Account/Login";
import CustomerManagementScreen from "./CustomerManagementScreen";
import { ROUTES } from "./Route";

export default function UserManagement() {
  const [page, setPage] = useState(ROUTES.USER_MANAGEMENT);

  switch (page) {
    case ROUTES.USER_MANAGEMENT:
      return <CustomerManagementScreen setPage={setPage} />;
    default:
      return <Login />;
  }
}
