import React from "react";

import { IoIosArrowBack } from "react-icons/io";
import AppSettings from "./AppSettings";
import ChangePassword from "./ChangePassword";
import ContactUs from "./ContactUs";
import { ROUTES } from "./contants";
import EditProfile from "./EditProfile";
import PrivacyPolicy from "./PrivacyPolicy";
import styles from "./styles/Details.module.scss";
import TermsAndConditions from "./TermsAndConditions";

export default function Details({ isMobile = false, route = "", setRoute }) {
  const handleBackButtonClck = () => {
    setRoute("");
  };

  return (
    <div className={styles.Details}>
      {!isMobile ? (
        ""
      ) : (
        <span className={styles.backButton} onClick={handleBackButtonClck}>
          <IoIosArrowBack />
        </span>
      )}
      {route === ROUTES.EDIT_PROFILE ? (
        <EditProfile />
      ) : route === ROUTES.CHANGE_PASSWORD ? (
        <ChangePassword />
      ) : route === ROUTES.TERMS_CONDITIONS ? (
        <TermsAndConditions />
      ) : route === ROUTES.PRIVACY_POLICY ? (
        <PrivacyPolicy />
      ) : route === ROUTES.CONTACT_US ? (
        <ContactUs />
      ) : route === ROUTES.APP_SETTINGS ? (
        <AppSettings />
      ) : (
        ""
      )}
    </div>
  );
}
