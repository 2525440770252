import { createApi, fetchBaseQuery } from "@reduxjs/toolkit/query/react";
import { API_URL, INITIAL_TOKEN } from "../../config/config";
import { getToken } from "./localStorageService";

export const baseApi = createApi({
  reducerPath: "baseApi",
  baseQuery: fetchBaseQuery({
    baseUrl: API_URL,
    prepareHeaders: (headers, { getState }) => {
      headers.set("token", INITIAL_TOKEN);
      headers.set("Accept", "application/json");
      let auth_token = getToken();
      if (auth_token) {
        headers.set("authorization", auth_token);
      }
      return headers;
    },
  }),
  endpoints: () => ({}),
});
