import React, { lazy, Suspense, useEffect, useState } from "react";
import "react-bootstrap-table-next/dist/react-bootstrap-table2.min.css";
import "react-bootstrap-table2-toolkit/dist/react-bootstrap-table2-toolkit.min.css";
import { Input, SubTitle } from "../../Components/CustomComponents/index.js";
import Table from "../../Components/Table/Table.js";
import styles from "./styles/user_management.module.scss";
// import { ToastContainer, toast } from "react-toastify";

//
import { CgBlock, CgUnblock } from "react-icons/cg";
import { MdDeleteForever, MdDone } from "react-icons/md";
import { useDispatch } from "react-redux"; // for getting and setting data into local redux
// import CustomerBlockModal from "../../Components/Modals/CustomerBlockModal";
import { Col, Row, Spinner } from "react-bootstrap";
import { useGetAllRestaurantQuery } from "../../Data/services/restaurantApi.js";
import {
  useBlockUserMutation,
  useDeleteUserMutation,
  useUnBlockUserMutation,
} from "../../Data/services/userApi.js";
import Toast, { SuccessToast } from "../../Hooks/useToast.js";
import CustomLoader from "../../Components/CustomComponents/CustomLoader.js";
import DeleteModal from "../../Components/Modals/DeleteModal.js";

const CustomerBlockModal = lazy(() =>
  import("../../Components/Modals/CustomerBlockModal.js")
);

const RestaurantManagementScreen = ({ setPage }) => {
  const dispatch = useDispatch();

  // const [data, setData] = useState([]);
  const [indicationMessage, setIndicationMessage] = useState("loading...");
  const [rowData, setRowData] = useState([]);
  const [editedData, setEditedData] = useState("");

  const [loading, setLoading] = useState(false);
  const [blockModal, setBlockModal] = useState(false);
  const [forBlock, setForBlock] = useState(true);
  const [editModal, setEditModal] = useState(false);
   const [deleteModal, setDeleteModal] = useState(false);
  

  const [currentPage, setCurrentPage] = useState(1); // todo
  const [pageSize, setPageSize] = useState(10); // todo
  const [totalRecords, setTotalRecords] = useState(0); // todo
  const [totalPages, setTotalPages] = useState(0); // todo
  const [dataFetched, setDataFetched] = useState(false); // todo

  const [searchQuery, setSearchQuery] = useState("");

  const { data, isSuccess, isLoading, isFetching, refetch } =
    useGetAllRestaurantQuery(
      {
        search: searchQuery.length >= 3 ? searchQuery : "",
        limit: pageSize,
        page: currentPage,
      },
      { refetchOnMountOrArgChange: true }
    );

  const handleSearchChange = (event) => {
    const query = event.target.value;
    setSearchQuery(query);

    setDataFetched(false);
  };

  useEffect(() => {
    if (data) {
      // console.log(data);
      setPageSize(data.links.per_page);
      setCurrentPage(data.links.current);
      setTotalRecords(data.links.total_records);
      setTotalPages(data.links.total);
      setRowData(data.data);
      setDataFetched(true);
    }
  }, [data]);

  const handleBlockModalOpen = () => {
    setBlockModal(true);
  };

  const handleBlockModalClose = () => {
    setBlockModal(false);
    setEditedData("");
  };

  const handleDeleteModalOpen = () => {
    setDeleteModal(true);
  };

  const handleDeleteModalClose = () => {
    setDeleteModal(false);
    setEditedData("");
  };

  const [blockUser, { isLoading: isBlocking }] = useBlockUserMutation();
  const [unBlockUser, { isLoading: isUnBlocking }] = useUnBlockUserMutation();

  const handleBlockOrUnblock = async (userSlug, isBlock) => {
    // console.log(userSlug);
    // console.log(isBlock);
    setLoading(true);
    // setTimeout(() => {
    //   setLoading(false);
    //   handleBlockModalClose();
    // }, 2000);
    try {
      let response;
      if (isBlock) {
        response = await blockUser(userSlug);
      } else {
        response = await unBlockUser(userSlug);
      }

      // console.log(response.data);

      if (response.data.code === 200) {
        await refetch();
        setLoading(false);
        handleBlockModalClose();
        SuccessToast(response.data?.message);
      }
    } catch (error) {
      // console.log(error);
      Toast(error.message, "error");
    }
  };

  const[deleteUser, {isLoading: isDeleting}] = useDeleteUserMutation();

  const deleteData = async (slug) => {


    try {
      const { data } = await deleteUser(slug);

      // console.log(data);

      if (data.code === 200) {
        await refetch();
        handleDeleteModalClose();
        SuccessToast(data.message);
      }
    } catch (error) {
      // console.log(error);
      Toast(error.message, "error");
    }
    
  };

  function indication() {
    // return something here
    // return 'Table is empty';
    return indicationMessage;
  }

  const columns = [
    {
      dataField: "name",
      text: "Name",
      sort: true,
      headerStyle: {
        width: "150px",
      },
      formatter: (cellContent, row) => {
        // return row.firstname + row.lastname;
        return (
          <div className="d-flex flex-row justify-content-start align-items-center gap-2">
            <img
              alt="user"
              src={row.image_url}
              loading="lazy"
              width={48}
              height={48}
              className="rounded-circle object-fit-cover"
            />
            <div className="lc-1"> {`${row.name}`} </div>
          </div>
        );
      },
    },
    {
      dataField: "email",
      text: "Email",
      // sort: true,
      headerStyle: {
        width: "150px",
      },
      formatter: (cellContent, row) => {
        return <span className="lc-1"> {row.email}</span>;
      },
    },
    {
      dataField: "mobile_no",
      text: "Phone",
      // sort: true,
      headerAlign: "center",
      align: "center",
      headerStyle: {
        width: "100px",
      },
      formatter: (cellContent, row) => {
        return <span className="lc-1"> {row.mobile_no}</span>;
      },
    },
    {
      dataField: "is_blocked",
      text: "Status",
      sort: true,
      headerAlign: "center",
      align: "center",
      headerStyle: {
        width: "100px",
      },
      formatter: (cellContent, row) => {
        if (row.is_blocked)
          return <div className={`${styles.block}`}>Block</div>;
        return (
          <div className={`${styles.active}`}>
            <span className="d-none d-xl-block">Active </span>
            <span className="d-block d-xl-none">
              <MdDone />
            </span>
          </div>
        );
      },
    },
    {
      dataField: "actions",
      text: "Actions",
      headerAlign: "center",
      align: "center",
      headerStyle: {
        width: "200px",
      },
      formatter: (cellContent, row) => {
        // console.log(row)
        if (row.slug) {
          return (
            <div className="d-flex justify-content-center align-items-center ">
              
              <div
                className="d-flex justify-content-center align-items-center bg-danger-hover rounded-3 p-2 mx-2 "
                role="button"
                onClick={() => {
                  setForBlock(true);
                  setEditedData(row);
                  handleBlockModalOpen();
                }}
              >
                <span className={`d-flex align-items-center pe-1`}>
                  <CgBlock />
                </span>
                <span className="d-none d-xl-block">Block</span>
              </div>
              <div
                className="d-flex justify-content-center align-items-center bg-success-hover rounded-3 p-2 mx-2"
                role="button"
                onClick={() => {
                  setForBlock(false);
                  setEditedData(row);
                  handleBlockModalOpen();
                }}
              >
                <span className={`d-flex align-items-center pe-1`}>
                  <CgUnblock />
                </span>
                <span className="d-none d-xl-block">UnBlock</span>
              </div>
              <div
                                            className="d-flex justify-content-center align-items-center bg-danger text-white rounded-3 p-2 mx-2 "
                                            role="button"
                                            onClick={() => {
                                              setEditedData(row);
                                              handleDeleteModalOpen();
                                            }}
                                          >
                                            <span className={`d-flex align-items-center pe-1`}>
                                              <MdDeleteForever />
                                            </span>
                                            <span>Delete</span>
                                          </div>
            </div>
          );
        }
      },
    },
  ];

  return (
    <div className={styles.MainContainer}>
      {blockModal ? (
        <Suspense fallback={<Spinner animation="grow" size="sm" />}>
          <CustomerBlockModal
            forBlock={forBlock}
            handleBlockModalClose={handleBlockModalClose}
            data={editedData}
            onAction={handleBlockOrUnblock}
            loading={loading}
          />
        </Suspense>
      ) : (
        ""
      )}

{deleteModal ? (
        <Suspense fallback={<Spinner animation="grow" size="sm" />}>
          <DeleteModal
            handleDeleteModalClose={handleDeleteModalClose}
            data={editedData}
            isDeleting={isDeleting}
            onDelete={deleteData}
          />
        </Suspense>
      ) : (
        ""
      )}

      {
        //   editModal ? (
        //   <EditUserModal
        //     handleEditModalClose={handleEditModalClose}
        //     data={editedData}
        //   />
        // ) : (
        //   ""
        // )
      }

      <div className={styles.headerContainer}>
        <Row className="w-100 align-items-center">
          <Col md={6}>
            <div>
              <SubTitle text={"Restaurant Management"} classes="text-black" />
            </div>
          </Col>

          <Col md={6}>
            <Input
              placeholder="search by name or email"
              value={searchQuery}
              onChange={handleSearchChange}
            />
          </Col>
        </Row>

        {
          // <Row>
          //   <Col md={12}>
          //     <Button
          //       classes={`${styles.cardBtn} `}
          //       text="Add User"
          //       onClick={handleAddUser}
          //     ></Button>
          //   </Col>
          // </Row>
        }
      </div>

      <div className={styles.mainTable}>
        {!dataFetched || !isSuccess ? (
          <CustomLoader />
        ) :
          (<Table
            data={rowData}
            columns={columns}
            indication={indication}
            totalRecords={totalRecords} // todo
            totalPages={totalPages} // todo
            pageSize={pageSize} // todo
            currentPage={currentPage} // todo
            setCurrentPage={setCurrentPage} // todo
            setPageSize={setPageSize} // todo
            refetch={refetch} // todo
          />)
        }
      </div>
    </div>
  );
};

export default RestaurantManagementScreen;
