import React from "react";
import { FaCircleXmark } from "react-icons/fa6";
import ModalContainer from "../ModalContainer";
import cssStyles from "./styles/editModal.module.scss";
import { Spinner } from "react-bootstrap";

const DeleteModal = ({
  handleDeleteModalClose,
  data,
  onDelete,
  isDeleting,
}) => {
  return (
    <div>
      <ModalContainer handleClose={handleDeleteModalClose}>
        <div className={cssStyles.modalContainer}>
          <div className="d-flex align-items-center w-100 justify-content-between mb-4">
            <p className="fs-4 fw-medium m-0">Confirm Delete</p>
            <span className="bg-dark bg-opacity-75 d-flex p-3 rounded-5">
              <FaCircleXmark
                role="button"
                onClick={handleDeleteModalClose}
                style={{ cursor: "pointer" }}
              />
            </span>
          </div>
          <div>
            <p>
              Do you want to delete{" "}
              <b>
                {data?.business_title} {data?.cuisine_title}
              </b>
              ?
            </p>

            <div className="d-flex justify-content-center align-items-center">
              <div
                className="d-flex justify-content-center align-items-center rounded-3 p-2 mx-2 bg-dark text-white"
                role="button"
                onClick={handleDeleteModalClose}
              >
                <span className={`mx-2 d-flex`}>Cancel</span>
              </div>

              <div
                className="d-flex justify-content-center align-items-center rounded-3 p-2 mx-2 bg-danger text-white"
                role="button"
                onClick={() => {
                  onDelete(data.slug);
                }}
              >
                <span className={`mx-2 d-flex ${isDeleting ? "p-1" : ""}`}>
                  {isDeleting ? (
                    <Spinner animation="grow" size="sm" className={`mx-3 `} />
                  ) : (
                    "Delete"
                  )}
                </span>
              </div>
            </div>
          </div>
        </div>
      </ModalContainer>
    </div>
  );
};

export default DeleteModal;
