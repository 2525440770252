import React, { useEffect, useState } from "react";
import { Col, Row, Spinner } from "react-bootstrap";
import { FaCircleXmark } from "react-icons/fa6";
import { Button, Input } from "../CustomComponents";
import ModalContainer from "../ModalContainer";
import cssStyles from "./styles/editModal.module.scss";
import Toast, { SuccessToast } from "../../Hooks/useToast";
import { useAddCuisineTypeMutation, useUpdateCuisineTypeMutation } from "../../Data/services/cuisineTypeApi";
import _ from "lodash";

const EditCuisineTypeModal = ({ handleEditModalClose, editData, refetch }) => {
  const [rowId, setRowId] = useState("");
  const [cuisineTitle, setCuisineTitle] = useState("");
  const [sending, setSending] = useState(false);

  const [mounted, setMounted] = React.useState(false);
  useEffect(() => {
    setMounted(true);
  }, []);

  useEffect(() => {
    // console.log(editData.cuisine_title);
    // console.log(editData.slug);
    if (editData) {
      setRowId(editData.slug);
      setCuisineTitle(editData.cuisine_title);
    }
  }, [editData, mounted]);

  const handleCuisineTitle = (e) => {
    // console.log(e.target.value);
    setCuisineTitle(e.target.value);
  };

  const [addCuisineType, { isLoading: adding }] = useAddCuisineTypeMutation();
  const [updateCuisineType, { isLoading: updating }] =
    useUpdateCuisineTypeMutation();

  const handleSubmit = async (e) => {
    // console.log(rowId);
    // console.log(cuisineTitle);

    if (cuisineTitle.length <= 2 || _.isEmpty(cuisineTitle)) {
      Toast("Minimum 3 characters required.", "error");
      return;
    }

    const data = {
      cuisine_title: cuisineTitle,
    };

    setSending(true);
    try {
      let response;
      if (editData) {
        response = await updateCuisineType({ data, slug: rowId });
      } else {
        response = await addCuisineType(data);
      }

      if (response.data.code === 200) {
        refetch();
        setSending(false);
        SuccessToast(response?.data.message);
        handleEditModalClose();
      }
    } catch (error) {
      if (error?.response && error?.response?.data?.message) {
        Toast(error?.response?.data?.message, "error");
      } else {
        Toast("An error occurred during login", "error");
      }
      handleEditModalClose();
      setSending(false);
    }
  };

  if (!mounted) {
    return;
  }

  return (
    <div>
      <ModalContainer handleClose={handleEditModalClose}>
        <div className={cssStyles.modalContainer}>
          <div className="d-flex align-items-center w-100 justify-content-between mb-4">
            <p className="fs-4 fw-medium m-0 text-black">
              {editData ? "Edit Cuisine Type" : "Add Cuisine Type"}
            </p>
            <span className="bg-dark bg-opacity-75 d-flex p-3 rounded-5">
              <FaCircleXmark
                role="button"
                onClick={handleEditModalClose}
                style={{ cursor: "pointer" }}
              />
            </span>
          </div>
          <div className="w-100">
            {
              <Row>
                <Col md={12}>
                  <Input
                    classes="mt-2 mb-3"
                    type="text"
                    placeholder="Enter cuisine type"
                    value={cuisineTitle}
                    onChange={handleCuisineTitle}
                  />
                </Col>
              </Row>
            }

            <Row>
              <Col md={12}>
                <Button
                  classes={`${cssStyles.cardBtn} mt-4 `}
                  text={sending ? (
                    <Spinner
                      animation="grow"
                      size="sm"
                      className="mx-3 my-1"
                    />
                  ) : editData ? (
                    "Update"
                  ) : (
                    "Save"
                  )
                }
                  onClick={handleSubmit}
                ></Button>
              </Col>
            </Row>
          </div>
        </div>
      </ModalContainer>
    </div>
  );
};

export default EditCuisineTypeModal;
