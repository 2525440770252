import React from "react";
import { Col, Row } from "react-bootstrap";
import ClipLoader from "react-spinners/ClipLoader";

const CustomLoader = ({}) => {
  return (
    <Row
      className="w-100 d-flex justify-content-center align-items-center"
      style={{ height: "80vh" }}
    >
      <Col className="text-center">
        <ClipLoader color="#1C75BC" />
      </Col>
    </Row>
  );
};

export default CustomLoader;
